import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-atomik-1.png";
import explore2 from "../assets/images/explore/explore-atomik-2.png";
import exploreMap from "../assets/images/explore/explore-atomik-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import AngularJsIcon from "../assets/images/explore/AngularJsIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import "./MusikarExplorePage.css";

function AtomikAIExplorePage() {
  const bannerData = {
    heading: "Unlocking Business Growth with Atomik AI",
    description:
      "Discover how Atomik AI is revolutionizing data management with its AI-driven unified system of record. By transforming customer data into actionable insights, businesses can streamline operations, enhance decision-making, and drive smarter outcomes. ",
    img_src: explore1,
    aboutProject: {
      heading: "About this project",
      description:
        "Atomik AI is an AI-driven unified system of record platform designed to transform customer data into business growth by streamlining operations, enhancing decision-making, and optimizing business outcomes.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "AI-Driven Data Analytics",
        "Business Automation",
        "Decision Intelligence",
        "System Integration",
      ],
    },
    scope: {
      heading: "Scope",
      description: "App redesign",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "App Development",
        "AI Analytics",
        "Business Automation",
        "Data Processing",
        "Testing & QA",
        "System Deployment",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Data is at the core of modern business decisions. The client sought an AI-powered system that transforms raw data into actionable insights, streamlining operations and automating workflows for smarter business strategies.",
    heading2: "About the Product",
    description2:
      "Atomik AI is an AI-driven data intelligence platform that helps organizations leverage advanced analytics, automate decision-making, and enhance productivity. It integrates seamlessly with existing systems, providing real-time insights for optimized operations and growth.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "AI-Powered Data Insights – Transform raw data into strategic decisions.",
      "Business Process Automation – Streamline workflows for efficiency.",
      "Predictive Analytics – Forecast trends with machine learning.",
      "Seamless System Integration – Connect with existing business applications.",
      "Customizable Dashboards – Visualize key metrics with real-time analytics.",
      "Scalability & Performance – Adapt to business growth and demands.",
      "Automated Alerts & Notifications – Stay updated on critical data insights.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
      {
        img_src: AngularJsIcon,
        description:
          "We use Angular as a powerful front-end framework that enables the development of dynamic and scalable web applications.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default AtomikAIExplorePage;
