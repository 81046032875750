import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-dress-1.png";
import explore2 from "../assets/images/explore/explore-dress-2.png";
import exploreMap from "../assets/images/explore/explore-dress-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import "./MusikarExplorePage.css";

function DressMeProExplorePage() {
  const bannerData = {
    heading: "Refining Styling with DMP",
    description:
      "See how Dress Me Pro is redefining fashion styling and wardrobe management with personalized outfit recommendations. By analyzing user preferences, it helps individuals build the perfect closet for every occasion. ",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Dress Me Pro is a fashion styling and wardrobe management platform designed to offer personalized outfit suggestions, organize wardrobes, and help users dress with confidence for any occasion.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Personalized Styling",
        "Wardrobe Management",
        "Shopping Assistance",
        "Event-Specific Outfits",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Mobile Development",
        "AI Styling",
        "Wardrobe Management",
        "Shopping Assistance",
        "Testing & QA",
        "Final Deployment",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "The client envisioned an intelligent fashion platform that offers personalized styling advice and wardrobe management. Understanding that modern consumers seek convenience and tailored experiences, the goal was to create an AI-powered fashion assistant that simplifies outfit selection and enhances shopping experiences.",
    heading2: "About the Product",
    description2:
      "Dress Me Pro is a smart fashion and styling platform that helps users curate their wardrobes based on preferences, trends, and occasions. It offers AI-driven outfit suggestions, shopping recommendations, and closet organization tools, making fashion decisions effortless while ensuring a personalized experience.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "AI Styling Suggestions – Personalized outfit recommendations for every occasion.",
      "Smart Shopping Assistant – Find fashion items that match personal style.",
      "Wardrobe Management – Organize clothing collections digitally.",
      "Event-Specific Outfits – Get fashion advice for formal, casual, and seasonal looks.",
      "Mix & Match Feature – Experiment with outfit combinations virtually.",
      "Exclusive Brand Discounts – Access deals on top fashion brands.",
      "Virtual Fashion Consultation – Connect with personal stylists for expert advice.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default DressMeProExplorePage;
