import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-asset-1.png";
import explore2 from "../assets/images/explore/explore-asset-2.png";
import exploreMap from "../assets/images/explore/explore-asset-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import SalesforceIcon from "../assets/images/explore/SalesforceIcon.svg";
import AngularJsIcon from "../assets/images/explore/AngularJsIcon.svg";
import "./MusikarExplorePage.css";

function AssetLogikExplorePage() {
  const bannerData = {
    heading: "Revolutionizing Asset Management",
    description:
      "Discover how Asset Logik simplifies asset tracking, quoting, billing, service ticketing, and financial operations in a single platform. By optimizing asset utilization, automating workflows, and providing real-time insights, businesses can enhance efficiency and maximize profitability. ",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Asset Logik is an all-in-one asset management platform designed to streamline quoting, billing, service ticketing, inventory tracking, and financial operations with real-time insights.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Asset Tracking & Management",
        "Quoting & Billing",
        "Service Ticketing",
        "Inventory Control",
      ],
    },
    scope: {
      heading: "Scope",
      description: "App redesign",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Mobile Development",
        "Asset Tracking",
        "Billing System",
        "Service Ticketing",
        "Testing & QA",
        "System Deployment",
        "Research & Planning",
      ],
    },
  };

  const aboutData = {
    heading1: "About the client",
    description1:
      "Managing assets, finances, and inventory efficiently is crucial for businesses. The client needed an all-in-one solution that simplifies asset tracking, automates billing, and improves operational visibility.",
    heading2: "About the product",
    description2:
      "Asset Logik is a comprehensive asset management platform designed to streamline inventory tracking, service ticketing, and financial operations. It enhances business efficiency by providing real-time data insights and automation tools for effective resource management.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Asset Lifecycle Management – Track and maintain equipment usage.",
      "Inventory Control – Monitor stock levels in real time.",
      "Automated Billing & Quoting – Simplify financial operations.",
      "Service Ticketing System – Manage repairs and maintenance requests.",
      "Integration with ERP Systems – Sync with enterprise resource planning tools.",
      "Real-Time Usage Analytics – Optimize asset allocation and utilization.",
      "Automated Maintenance Reminders – Prevent downtime with proactive alerts.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
      {
        img_src: SalesforceIcon,
        description:
          "We use Salesforce as a leading CRM platform that enhances customer relationship management and business process automation.",
      },
      {
        img_src: AngularJsIcon,
        description:
          "We use Angular as a powerful front-end framework that enables the development of dynamic and scalable web applications.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default AssetLogikExplorePage;
