import React, { useEffect, useRef } from "react";
import "./AIBanner.css";
import AiBannerGrid from "../assets/images/ai-banner-grid.svg";
import AiBannerVideo from "../assets/videos/ai-banner-video.webm";
function AIBanner() {
  const AIVideoRef = useRef(null);

  useEffect(() => {
    const video = AIVideoRef.current;

    if (video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.error("Autoplay error:", error);
          });
      }
    }
  }, []);
  return (
    <div className="ai-banner">
      <div className="row custom-container py-lg-5 my-xxl-5">
        <div className="content-section col-lg-6">
          {/* <h1>AI & LLM Solutions</h1> */}
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-offset="30%"
          >
            <h1 className="characterbackground mt-lg-5">
              <div className="circle-spacing">A</div>
              <span>I & LLM</span>
            </h1>
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-offset="30%"
            >
              Solutions
            </h1>
          </div>
          <p className="mt-5">
            Unlock the full potential of your business with Sixlogs
            Technologies' AI solutions. Our expertise in artificial intelligence
            allows you to automate processes, gain insightful analytics, and
            deliver superior customer experiences.
          </p>
          <p className="mt-4">
            From machine learning models to natural language processing, our
            tailored AI services address your unique business challenges. Dive
            into the future with intelligent systems that not only understand
            but predict and optimize your business operations. Let us guide you
            through the AI landscape, ensuring seamless integration and
            transformative results.
          </p>
        </div>
      </div>
      <div className="video-section">
        <img loading="lazy" src={AiBannerGrid} alt="" />
      </div>
      <div className="video-section">
        <video
          ref={AIVideoRef}
          muted
          playsInline
          loop
          controls={false}
          style={{
            width: "100%",
          }}
        >
          <source src={AiBannerVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default AIBanner;
