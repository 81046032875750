import React from "react";
import HeroBanner from "../components/HeroBanner";
import ServicesCard from "../components/ServicesCard";
import hubspotBanner from "../assets/images/hubspot-banner.svg";
import SolutionOverview from "../components/SolutionOverview";
import ContactForm from "../components/ContactForm";
import Platform1 from "../assets/images/inner-pages-images/hubspot-1.svg";
import Platform2 from "../assets/images/inner-pages-images/hubspot-2.png";
import SolutionOverviewImg from "../assets/images/inner-pages-images/hubspot-3.png";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";

function HubSpotPage() {
  const bannerData = {
    pageName: "HubSpot Solution",
    description: [
      "Building the right team is crucial for business success, especially as companies scale and adapt. HubSpot solutions provide a flexible approach to meet your growing demands by enabling you to onboard top-tier experts without long-term commitments. This model ensures access to the industry’s leading talent, customized to your business objectives.",
      "HubSpot’s service offerings bring speed, expertise, and agility to help scale your team efficiently. Whether you need specialized skills for a new campaign or want to optimize workflows, our HubSpot experts seamlessly integrate with your team to drive productivity and innovation.",
    ],
    imgsrc: hubspotBanner,
  };

  const serviceData = [
    {
      title: "Dedicated Experts",
      description:
        "Our HubSpot-certified specialists offer dedicated support aligned with your business goals. From CRM management to inbound marketing, our experts work exclusively with your team to ensure smooth execution and measurable success.",
    },
    {
      title: "Top 1% HubSpot Talent",
      description:
        "Gain access to the top 1% of HubSpot-certified professionals. Our rigorous selection process ensures only the most experienced specialists work on your projects, bringing advanced automation, marketing, and sales expertise.",
    },
    {
      title: "24/7 Support & Assistance",
      description:
        "Our round-the-clock support ensures quick resolution of any HubSpot-related challenges. Whether optimizing campaigns, automating workflows, or troubleshooting, our experts are available whenever you need them to maintain efficiency.",
    },
    {
      title: "Full Oversight & Control",
      description:
        "Maintain complete control over your marketing and sales processes while benefiting from expert guidance. Our HubSpot professionals integrate seamlessly into your strategy, ensuring transparency and alignment with your business vision.",
    },
    {
      title: "Save Time",
      description:
        "Accelerate your growth by eliminating the lengthy onboarding process. With our HubSpot-certified experts ready to deploy, you can implement changes instantly, ensuring continuous improvement and a faster return on investment.",
    },
    {
      title: "Unmatched Expertise",
      description:
        "Our HubSpot specialists bring extensive experience across marketing, sales, automation, and CRM integration. Whether optimizing pipelines or enhancing customer engagement, our experts help you maximize your HubSpot investment for sustained success.",
    },
  ];

  const platformData = {
    pageName: "hub-spot",
    imgsrc: Platform1,
    title: "Seamless Integration – Maximum Impact",
    description:
      "At Sixlogs, we understand that every business has unique needs. That’s why our HubSpot-certified experts seamlessly integrate into your team, ensuring maximum efficiency and impact. Whether you need CRM strategists, automation specialists, content marketers, or data analysts, we provide the right professionals to optimize your HubSpot experience.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "Seamless Integration:",
        ulDescription:
          "Our HubSpot experts work as an extension of your team, aligning with your workflows, strategy, and company culture.",
      },
      {
        ulHeading: "Enhanced Capabilities:",
        ulDescription:
          "Unlock HubSpot’s full potential by leveraging specialists who refine automation, optimize lead generation, and streamline marketing efforts.",
      },
      {
        ulHeading: "Competitive Advantage:",
        ulDescription:
          "Stay ahead of the competition with agile, data-driven strategies that adapt to evolving market trends and customer needs.",
      },
    ],
  };

  const lightningData = {
    pageName: "hub-spot",
    imgsrc: Platform2,
    title: "Flexible Solutions – Tailored to Your Business",
    description:
      "When it comes to leveraging HubSpot, businesses often face a choice: augment their team with expert consultants or outsource to a dedicated managed services team. At Sixlogs, we offer both solutions to help you scale efficiently.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "HubSpot Consulting:",
        ulDescription:
          "Need specialized support for a specific project? Our on-demand HubSpot experts integrate seamlessly with your team, providing insights on CRM customization, sales automation, and marketing optimizations—all while you maintain full control over execution.",
      },
      {
        ulHeading: "Managed HubSpot Services:",
        ulDescription:
          "Prefer a hands-off approach? Our dedicated HubSpot teams handle everything—from strategy to execution. With project managers, developers, automation specialists, and content strategists, we ensure end-to-end implementation of high-impact HubSpot solutions.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "hub-spot",
    title: "Maximizing Efficiency - Driving Growth",
    imgsrc: SolutionOverviewImg,
    description: [
      "Many businesses invest in HubSpot but only scratch the surface of its capabilities. Without the right strategy, tools, and expertise, companies miss opportunities to streamline workflows, personalize customer experiences, and drive revenue growth.",
      "The key to maximizing HubSpot’s potential lies in seamless integration with your existing operations. Our HubSpot experts work alongside your team, ensuring every feature—from CRM automation to data analytics—is optimized to enhance efficiency and decision-making.",
      "With the right support, HubSpot becomes more than just a tool—it’s a growth engine that empowers your sales, marketing, and service teams to work smarter, automate repetitive tasks, and deliver personalized experiences at scale.",
    ],
  };

  return (
    <div>
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      {/* <OurExpertise /> */}
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default HubSpotPage;
