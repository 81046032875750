import React, { useState } from "react";
import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";

import CrossIcon from "../assets/images/CrossIcon.svg";
import PlusIcon from "../assets/images/PlusIcon.svg";
import CustomButton from "./custom/CustomButton";
import { useNavigate } from "react-router-dom";

function AugmentationServices(props) {
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };
  const AugmentationServices = [
    {
      heading: "The Main Purpose of IT Staffing is to Reduce Costs",
      description:
        "A common misconception is that the primary goal of IT staff augmentation is merely to cut costs. While cost-efficiency is certainly a benefit, the main advantage lies in the flexibility and access to specialized skills it offers. IT staff augmentation enables organizations to quickly scale their teams, bringing in experts who can immediately contribute to ongoing projects without the long-term commitment and overhead associated with full-time hires.",
    },
    {
      heading: "What does the recruitment process look like?",
      description:
        "Using advanced SEO techniques and data-driven strategies, The FBAXperts improves product rankings on Amazon. We analyze market trends and optimize listings with impactful keywords, ensuring your products attract a broader audience.",
    },
    {
      heading:
        "I didn't get through the recruitment process the first time. Can I try again?",
      description:
        "Choosing FBAXperts means seeing a noticeable increase in product visibility, improved rankings, and a boost in sales. Our clients experience enhanced market performance thanks to our targeted optimization and marketing strategies.",
    },
    {
      heading: "Will I be recruited for a specific project?",
      description:
        "The FBAXperts provide customized strategies for various product types. Recognizing the unique demands of each niche, we adapt our approach to your specific product category, ensuring the most effective marketing and optimization.",
    },
    {
      heading: "How do you care for the self-development of employees?",
      description:
        "Creating an online e-commerce store takes approximately three to four weeks for a new business to set up an e-commerce store. This goal is achievable for any company interested in FBAXperts to help you set up your online store within this period because we have professional marketers and business advisors with immense experience. Hiring our services, you will get only high quality work and the best practices that will contribute to the success of your e-commerce business.",
    },
  ];
  return (
    <div>
      <div className="augmentation py-5">
        <div className="custom-container">
          <div className="row h-100 py-5">
            <div className="col-md-6 ">
              <div className="d-flex flex-column h-100 justify-content-between">
                <h1>
                  Common Misconceptions{" "}
                  <span className="d-lg-block">IT Staff Augmentation</span>
                </h1>
                <div
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    navigateToPage("/contact-us");
                  }}
                >
                  <CustomButton btntext={"Let’s Work Together"} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordian">
                {AugmentationServices?.map((item, i) => (
                  <div className="item" key={i}>
                    <div className="title" onClick={() => toggle(i)}>
                      <div className="title-inner">
                        {selected === i ? (
                          <h5 className="text-theme-white ">
                            {item.heading}
                          </h5>
                        ) : (
                          <h5 className="text-theme-white ">
                            {item.heading}
                          </h5>
                        )}
                        {selected === i ? (
                          <img loading="lazy" src={CrossIcon} alt="icon" width={18} />
                        ) : (
                          <img loading="lazy" src={PlusIcon} alt="icon1" width={20} />
                        )}
                      </div>

                      <div
                        className={selected == i ? "content show" : "content"}
                      >
                        <div className="">
                          <div className="row d-md-flex align-items-center">
                            <div className="col-md-12">
                              <p className="text-theme-light-white  description-ethicalessence pt-3">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container text-center d-flex flex-column align-items-center py-5 mt-5">
        <h1>
          Start Building Your Development Team with{" "}
          <span className="d-lg-block">Sixlogs Technologies Today!</span>{" "}
        </h1>
        <div className="mt-3 mt-xl-5 ">
          <div
            style={{ width: "fit-content" }}
            onClick={() => {
              navigateToPage("/contact-us");
            }}
          >
            <CustomButton btntext={"Contact us"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AugmentationServices;
