import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-ww-1.png";
import explore2 from "../assets/images/explore/explore-ww-2.png";
import exploreMap from "../assets/images/explore/explore-ww-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";

import "./MusikarExplorePage.css";

function WorkWalletExplorePage() {
  const bannerData = {
    heading: "Simplifying Transactions with Work Wallet",
    description:
      "Learn how Work Wallet is revolutionizing digital payments with a seamless and secure financial management platform. Businesses and individuals can send, receive, and manage funds effortlessly while accessing tailored financial services.",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Work Wallet is a digital payments and financial management platform designed to enable secure transactions, streamline money transfers, and provide tailored financial services for businesses and individuals.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Digital Payments",
        "Financial Management",
        "Business & Individual Accounts",
        "Security & Fraud Prevention",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Mobile Development",
        "Payment Integration",
        "Security Features",
        "Transaction Tracking",
        "Compliance Handling",
        "Deployment & Support",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Work Wallet was developed to provide secure and efficient digital financial solutions for businesses and individuals. The client sought to create a versatile payment platform that simplifies transactions, enhances financial security, and streamlines expense management. Their focus was on accessibility and user convenience.",
    heading2: "About the Product",
    description2:
      "Work Wallet is a digital payments and financial management platform that facilitates secure money transfers, transaction tracking, and financial reporting. Designed for both businesses and personal use, it integrates fraud detection and compliance features to ensure safety. Its user-friendly interface makes financial operations more efficient.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Digital Payments – Secure transactions with real-time processing.",
      "Financial Management – Track expenses, invoices, and transaction history.",
      "Security & Fraud Prevention – AI-powered fraud detection and risk management.",
      "Personal & Business Accounts – Tailored financial solutions for different users.",
      "Expense Categorization – Automated tracking for budgeting and tax planning.",
      "Invoice Generation – Create and send invoices seamlessly.",
      "Multi-Currency Support – Process international transactions effortlessly.",
    ],

    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default WorkWalletExplorePage;
