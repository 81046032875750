import React from "react";
import CustomButton from "./custom/CustomButton";
import { useNavigate } from "react-router-dom";

function NearShore() {
  const navigate = useNavigate();
  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  return (
    <div className="mb-xl-5 mb-3">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-offset="30%"
        className="mt-5 d-flex flex-column justify-content-center w-100 "
      >
        <div className="d-md-flex gap-2 align-items-center mt-lg-5">
          <h1 className="characterbackground">
            <div className="circle-spacing">T</div>
            <span>he Leading</span>
          </h1>
          <h1 className="h1">
            <span className="d-lg-block">Nearshore Technology</span>
          </h1>
        </div>
        <h1 className="h1">
          <span className="d-lg-block">Solutions Company</span>
        </h1>
      </div>

      <p className="w-100 py-2">
        We take pride in delivering state-of-the-art technology solutions that
        revolutionize businesses. Our expertise in designing and developing
        scalable, high-performance software enables us to tackle your most
        complex challenges. By leveraging our vast tech knowledge and
        cross-industry experience, we drive digital transformation and
        fast-track your path to success. Our mission is to create lasting value
        and innovation at every step.
      </p>
      <span className=" fs-4">
        Experience the difference with the Digital Acceleration Experts.
      </span>

      <div className="mt-3 mt-lg-5">
        <CustomButton
          btntext={"Contact Us"}
          func={() => navigateToPage("/contact-us")}
          // customStyle={{ padding: "15px 30px", borderRadius: 12 }}
        />
      </div>
    </div>
  );
}

export default NearShore;
