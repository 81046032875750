import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-feedback-1.png";
import explore2 from "../assets/images/explore/explore-feedback-2.png";
import exploreMap from "../assets/images/explore/explore-feedback-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import AngularJsIcon from "../assets/images/explore/AngularJsIcon.svg";
import SalesforceIcon from "../assets/images/explore/SalesforceIcon.svg";
import "./MusikarExplorePage.css";

function FeedbackWowExplorePage() {
  const bannerData = {
    heading: "Enhancing Brand Reputation with Feedback Wow",
    description:
      " Discover how Feedback Wow is helping businesses improve customer experience through intelligent feedback management. By collecting, analyzing, and responding to insights, brands can refine their strategies, boost reputation, and drive growth.",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Feedback Wow is a feedback management and social platform designed to help businesses collect, analyze, and respond to customer feedback to enhance brand reputation and drive growth.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Customer Feedback Collection",
        "Sentiment Analysis",
        "Reputation Management",
        "Response & Resolution Tools",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Frontend Development",
        "Web Integration",
        "Feedback Analytics",
        "Sentiment Tracking",
        "QA & Security",
        "System Deployment",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Businesses today rely on customer feedback to enhance products and services. The client sought a streamlined feedback management system that collects insights, analyzes sentiment, and strengthens brand engagement through actionable responses.",
    heading2: "About the Product",
    description2:
      "Feedback Wow is a comprehensive feedback and reputation management platform that helps businesses collect, analyze, and act on customer input. It integrates AI-driven sentiment analysis, automated response tools, and reputation monitoring to improve customer satisfaction and business growth.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Automated Survey Creation – Customizable feedback forms for customer insights.",
      "Sentiment Analysis – AI-powered insights from customer feedback data.",
      "Real-Time Alerts – Get notified about critical reviews instantly.",
      "Response Management – Automate responses to customer reviews.",
      "Reputation Monitoring – Track brand perception across multiple platforms.",
      "Multi-Channel Integration – Collect feedback via email, SMS, and in-app.",
      "Insight-Driven Analytics – Identify trends and make data-driven decisions",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      // {
      //   img_src: IOSIcon,
      //   description:
      //     "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      // },
      // {
      //   img_src: AndroidIcon,
      //   description:
      //     "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      // },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
      {
        img_src: AngularJsIcon,
        description:
          "We use Angular as a powerful front-end framework that enables the development of dynamic and scalable web applications.",
      },
      {
        img_src: SalesforceIcon,
        description:
          "We use Salesforce as a leading CRM platform that enhances customer relationship management and business process automation.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default FeedbackWowExplorePage;
