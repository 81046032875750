import React from "react";
import "./CareersPage.css";
import HeroBanner from "../components/HeroBanner";
import ContactForm from "../components/ContactForm";
import JobsCard from "../components/JobsCard";
import Careerpath from "../components/Careerpath";
import AboutPeople from "../components/AboutPeople";
import CoreValues from "../components/CoreValues";
import MarqueeCard from "../components/MarqueeCard";
import LearnAboutOurValues from "../components/LearnAboutOurValues";
import FaqSection from "../components/FaqSection";
import CareerBanner from "../components/CareerBanner";
import BannerCenterHeading from "../components/BannerCenterHeading";

function CareersPage() {
  const data = {
    tittle: [
      "Working at Sixlogs means Flexibility, Trust and Meaningful choices",
    ],
    description: [
      "We believe that our employees are our greatest asset at Sixlogs. Working with us means enjoying a flexible work environment where trust and collaboration are paramount. We empower our team members with meaningful choices, fostering a culture of innovation and growth. Join us to be part of a dynamic team that values your contributions and supports your professional development. Together, we drive digital transformation and create impactful solutions for our clients. Discover your potential and make a difference with Sixlogs.",
    ],
  };
  const FAQDATA = [
    {
      title: "What does the recruitment process look like?",
      description:
        "Our recruitment process is quick and simple: Step 1: Submit your resume. Step 2: We’ll call you for an initial video screening. Step 3: You’ll speak with the hiring manager. Step 4: Receive your offer.",
    },
    {
      title:
        "I didn't get through the recruitment process the first time. Can I try again?",
      description:
        "Yes, you can reapply in 6 months. Be sure to highlight your experience and what you’ve learned since our last conversation.",
    },
    {
      title: "Will I be recruited for a specific project?",
      description:
        "We are both a product and a services company, so you may work on our products or a diverse range of client projects.",
    },
    {
      title: "How do you care for the self-development of employees?",
      description:
        "We pride ourselves on personal growth and offer company-paid training and access to a wide range of experts to support your career goals. You can work with your manager to define a career path, and we’ll be with you every step of the way.",
    },
  ];

  return (
    <div className="careers font-face-DMSans-Regular">
      <div className="bg-linear">
        <div className="custom-container">
          <BannerCenterHeading
            isAbout={false}
            description={
              "At Sixlogs, we empower businesses with tailored, innovative solutions that enhance efficiency and drive growth."
            }
          />
        </div>
      </div>
      <CareerBanner data={data} />
      <div className="custom-container">
        <div className="grid-container"></div>

        <div className="d-flex align-items-end gap-2"></div>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          className="text-center d-flex flex-column justify-content-center align-items-center"
        >
          <div className="d-flex flex-column flex-sm-row gap-sm-2 align-items-center ">
            <h1 className="characterbackground h1">
              <div>W</div>
              <span>ork with</span>
            </h1>
            <h1 className="h1">
              <span className="d-lg-block">brands that</span>
            </h1>
          </div>
          <h1 className="h1">
            <span className="d-lg-block">make a difference</span>
          </h1>
        </div>
      </div>
      <MarqueeCard />
      <LearnAboutOurValues />
      <CoreValues />
      <AboutPeople />
      {/* <Careerpath /> */}
      {/* <JobsCard /> */}
      <FaqSection isHeading={true} FAQDATA={FAQDATA} />
      <ContactForm />
    </div>
  );
}

export default CareersPage;
