import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ClientsPage from "./pages/ClientsPage";
import CareersPage from "./pages/CareersPage";
import SalesForceServicesPage from "./pages/SalesForceServicesPage";
import MobileAppDevelopmentPage from "./pages/MobileAppDevelopmentPage";
import BusinessSolutionPage from "./pages/BusinessSolutionPage";
import ITStaffAugmentationPage from "./pages/ITStaffAugmentationPage";
import DigitalMarketingPage from "./pages/DigitalMarketingPage";
import ITSolutionPage from "./pages/ITSolutionPage";
import ArtificialIntelligencePage from "./pages/ArtificialIntelligencePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage.js";
import NavigationBar from "./layout/NavigationBar";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import loader from "../src/assets/images/loader2.gif";
import { useEffect, useState } from "react";
import Footer from "./layout/Footer.js";
import Aos from "aos";
import "aos/dist/aos.css";
import HubSpotPage from "./pages/HubSpotPage.js";
import MusikarExplorePage from "./pages/MusikarExplorePage.js";
import WorkWalletExplorePage from "./pages/WorkWalletExplorePage.js";
import DressMeProExplorePage from "./pages/DressMeProExplorePage.js";
import ProfitnessExplorePage from "./pages/ProfitnessExplorePage.js";
import FeedbackWowExplorePage from "./pages/FeedbackWowExplorePage.js";
import WalkerCraneExplorePage from "./pages/WalkerCraneExplorePage.js";
import HomeQExplorePage from "./pages/HomeQExplorePage.js";
import CloudShieldExplorePage from "./pages/CloudShieldExplorePage.js";
import AbleRiggingExplorePage from "./pages/AbleRiggingExplorePage.js";
import AtomikAIExplorePage from "./pages/AtomikAIExplorePage.js";
import AssetLogikExplorePage from "./pages/AssetLogikExplorePage.js";
import CenarioExplorePage from "./pages/CenarioExplorePage.js";

function App() {
  const [isHover, setHover] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    Aos.init({
      offset: window.innerHeight * 0.3,
      duration: 800,
      easing: "ease-in-out",
      once: true,
    });
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {loading ? (
        <div className="loading-screen ">
          <img
            loading="lazy"
            src={loader}
            alt="Loading Icon"
            className="img-fluid"
            width={150}
          />
        </div>
      ) : (
        <div>
          <NavigationBar isHover={isHover} />
          {location.pathname !== "/" && <div className="spacing-div"></div>}

          <Routes>
            <Route
              path="/"
              element={<HomePage isHover={isHover} setHover={setHover} />}
            />
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />

            <Route
              path="/salesforce-services"
              element={<SalesForceServicesPage />}
            />
            <Route path="/hubspot" element={<HubSpotPage />} />
            <Route
              path="/business-solution"
              element={<BusinessSolutionPage />}
            />
            <Route
              path="/custom-development"
              element={<MobileAppDevelopmentPage />}
            />

            <Route
              path="/staff-augmentation"
              element={<ITStaffAugmentationPage />}
            />
            <Route
              path="/digital-marketing"
              element={<DigitalMarketingPage />}
            />
            <Route path="/it-solution" element={<ITSolutionPage />} />
            <Route path="/ai" element={<ArtificialIntelligencePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {/* Explore */}
            <Route path="/explore/musikar" element={<MusikarExplorePage />} />
            <Route path="/explore/work-wallet" element={<WorkWalletExplorePage />} />
            <Route path="/explore/dress-me-pro" element={<DressMeProExplorePage />} />
            <Route path="/explore/profitness" element={<ProfitnessExplorePage />} />
            <Route path="/explore/feedback-wow" element={<FeedbackWowExplorePage />} />
            <Route path="/explore/walker-crane" element={<WalkerCraneExplorePage />} />
            <Route path="/explore/home-q" element={<HomeQExplorePage />} />
            <Route path="/explore/cloud-shield" element={<CloudShieldExplorePage />} />
            <Route path="/explore/able-rigging" element={<AbleRiggingExplorePage />} />
            <Route path="/explore/atomik-ai" element={<AtomikAIExplorePage />} />
            <Route path="/explore/asset-logik" element={<AssetLogikExplorePage />} />
            <Route path="/explore/cenario" element={<CenarioExplorePage />} />
          </Routes>
          <Footer />
        </div>
      )}
    </>
  );
}

export default App;
