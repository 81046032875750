import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "./custom/CustomButton";

function ReadyToBuild() {
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  return (
    <div className="ready d-flex flex-column align-items-center mt-5 py-5">
      <div className="ready-inner custom-container bg-white d-flex flex-column align-items-center mt-lg-5 py-5">
        <h1 className="text-center pt-lg-4">Ready to build your software?</h1>
        <p className="my-4 col-lg-6 text-center ">
          From fully managed teams to individual expert software developers, we
          specialize in delivering secure, scalable, and high-performance
          solutions tailored to your business needs.
        </p>
        <div
          style={{ width: "fit-content" }}
          onClick={() => {
            navigateToPage("/contact-us");
          }}
        >
          <CustomButton
            btntext={"Contact Us"}
            customStyle={{ padding: "12px 20px", borderRadius: 10 }}
          />
        </div>
      </div>
    </div>
  );
}

export default ReadyToBuild;
