import React from "react";
import Polygon from "../assets/images/Polygon.svg";
import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";
import CustomButton from "./custom/CustomButton";

function OurProcessOperation() {
  const sections = [
    {
      title: "Research",
      description:
        "Our process begins with comprehensive research to thoroughly understand your business, goals, and challenges. We delve deep into market trends, competitor analysis, and user needs to gather valuable insights. This research phase ensures that we have a solid foundation and clear direction for developing solutions that align with your strategic objectives",
    },
    {
      title: "Scoping session",
      description:
        "Our process begins with comprehensive research to thoroughly understand your business, goals, and challenges. We delve deep into market trends, competitor analysis, and user needs to gather valuable insights. This research phase ensures that we have a solid foundation and clear direction for developing solutions that align with your strategic objectives",
    },
    {
      title: "Project Set-up",
      description:
        "With the project scope defined, we proceed to the project set-up phase, where we create a strategic plan and organize the necessary resources. This includes assembling the project team, setting up project management tools, and establishing communication protocols. Our meticulous planning ensures that the project is structured for success and that all team members are prepared to execute efficiently.",
    },
    {
      title: "Project kickoff",
      description:
        "With the project scope defined, we proceed to the project set-up phase, where we create a strategic plan and organize the necessary resources. This includes assembling the project team, setting up project management tools, and establishing communication protocols. Our meticulous planning ensures that the project is structured for success and that all team members are prepared to execute efficiently.",
    },
  ];

  return (
    <div className="ourProcessOperation pt-5 py-lg-5 font-face-DMSans-Regular">
      <div className="custom-container">
        <div className="d-lg-flex justify-content-between ">
          <div className="col-lg-8">
            <h1>Our Process of Operation</h1>
            <p>
              Our business is built on the foundation of the top 1% of IT
              talent. We provide comprehensive technology solutions,
              meticulously crafted by elite teams that have undergone rigorous
              vetting processes to ensure unparalleled expertise and excellence.
            </p>
          </div>
          <div className=" col-lg-4 py-4 py-lg-0">
            <CustomButton btntext={"Let’s Work Together"} />

          </div>
        </div>
        <div className="d-none d-lg-flex mt-5" style={{ width: "100%" }}>
          <div className="d-flex align-items-center w-100">
            <h1 style={{ fontSize: "100px", color: "#D4D0CC" }}>01</h1>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "flex",
                  top: "-8px",
                  left: "50%",
                }}
              >
                <img loading="lazy" src={Polygon} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-100">
            <h1 style={{ fontSize: "100px", color: "#D4D0CC" }}>02</h1>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "flex",
                  top: "-8px",
                  left: "50%",
                }}
              >
                <img loading="lazy" src={Polygon} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-100">
            <h1 style={{ fontSize: "100px", color: "#D4D0CC" }}>03</h1>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: "black",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "flex",
                  top: "-8px",
                  left: "50%",
                }}
              >
                <img loading="lazy" src={Polygon} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-100">
            <h1 style={{ fontSize: "100px", color: "#D4D0CC" }}>04</h1>
          </div>
        </div>
        <div className="row mt-4">
          {sections.map((section, index) => (
            <div key={index} className="col-md-6 col-lg-3 pe-5">
              <h4>{section.title}</h4>
              <p>{section.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurProcessOperation;
