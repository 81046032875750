import React from "react";
import "./CustomButton.css";
import arrowuprighwhitet from "../../assets/images/arrowuprighwhitet.svg";

function CustomButton({ btntext, customStyle = {}, func }) {
  return (
    <div className="customButton font-face-DMSans-Regular">
      <div className="">
        <button onClick={func} className="d-flex gap-2" style={customStyle}>
          {btntext}
          <img loading="lazy" src={arrowuprighwhitet} alt="" className="img-fluid" />
        </button>
      </div>
    </div>
  );
}

export default CustomButton;
