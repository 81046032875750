import React from "react";
import "./TeamAchievements.css";
import saleforceCard from "../assets/images/salesforceCard.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function TeamAchievements() {
  const sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 690, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 675, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data = [
    "Administrator",
    "System Architect",
    "Platform Developer 2",
    "Application Architect",
    "Sales Cloud Consultant",
    "Service Cloud Consultant",
    "Marketing Cloud Administrator",
  ];

  return (
    <div className="team pt-5">
      <div className="custom-container pb-xxl-5">
        <div className="d-flex flex-column align-items-sm-center">
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Our
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <span className="pe-2 pe-md-3 show-upto">Our</span>
            <div className="circle-spacing">C</div>
            <span>ertified Team</span>
          </h1>

          <h4 className="">
            Unlock the Full Potential of Salesforce with Our Certified Experts
          </h4>

          <p>Our team includes experts with the following certifications:</p>
        </div>
        <div className="d-none d-xl-flex flex-wrap justify-content-center align-items-center ">
          {data.map((item, index) => (
            <div
              key={index}
              className="col-12 col-md-6 col-lg-4 col-xl-3 mt-5 px-2 px-lg-3"
            >
              <div className="card">
                <div className="card-img">
                  <img loading="lazy" className="w-100" src={saleforceCard} />
                </div>
                <h2 className="font-face-Syne-Bold">CERTIFIED</h2>
                <h5>{item}</h5>
              </div>
            </div>
          ))}
        </div>
        <div className="d-block d-xl-none">
          <Slider ref={sliderRef} {...settings}>
            {data.map((item, index) => (
              <div
                key={index}
                className="col-12 col-md-6 col-lg-4 col-xl-3 mt-5 px-2 px-lg-3"
              >
                <div className="card">
                  <div className="card-img">
                    <img loading="lazy" className="w-100" src={saleforceCard} />
                  </div>
                  <h2 className="font-face-Syne-Bold">CERTIFIED</h2>
                  <h5>{item}</h5>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default TeamAchievements;
