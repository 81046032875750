import React, { useRef } from "react";
import "./FeedBack.css";

import Coomas from "../assets/images/Coomas.svg";
import CustomButton from "./custom/CustomButton";
import MarqueeCard from "./MarqueeCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomLineText from "./custom/CustomLineText";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function FeedBack({ isInnerService = false }) {
  const quotes = [
    {
      quote:
        "This was a large project with several changes. Sixlogs team was very patient and did a fantastic job helping us find a solution to technical challenges.",
      author: "Zac Lake, Co-Founder",
      company: "BlueGreen Services",
    },
    {
      quote:
        "Sixlogs was awesome. Great job of understanding the project - made progress very very quickly, and managed my change requests timely and professionally.",
      author: "Spencer Kreikemeier",
      company: "Dynojet",
    },
    {
      quote:
        "It was a pleasure working with Sixlogs team! They maintained clear and consistent communication throughout the project and provided timely status updates. Our task was completed swiftly and efficiently. Their expertise as a web developer is remarkable!",
      author: "Khary Simon",
      company: "Hearst",
    },
    {
      quote:
        "I had a seamless Magento-to-Shopify migration with Sixlogs Technologies. They were responsive, detail-oriented, and insightful, ensuring everything was set up perfectly. Highly recommend them for a smooth e-commerce transition!",
      author: "Jesse Temple",
      company: "SPC Sports",
    },
    {
      quote:
        "This was the easiest process I have experienced yet with website work. Sixlogs team took the task I gave them and their team and did not make it complicated. Communication was excellent. Highly recommend.",
      author: "Paul Nash",
      company: "Bay Area Bottles",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,

    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <>
      {/* {!isInnerService && (
        <>
          <div className="d-flex justify-content-center pb-5">
            <CustomButton btntext={"More Success Stories"} />
          </div>
        </>
      )} */}
      <div className="feedback py-xxl-5">
        <div className="custom-container mainsection">
          {!isInnerService && (
            <>
              <MarqueeCard />

              <div className="text-center">
                <h2 className="font-face-Syne-Bold">
                  We deliver top quality solutions for world-class
                  <span className="d-lg-block">
                    clients from startups to Fortune 500 companies.
                  </span>
                </h2>
              </div>

              <div className="d-flex justify-content-center">
                <CustomButton
                  btntext={"Contact Us"}
                  func={() => navigateToPage("/contact-us")}
                />
              </div>
            </>
          )}

          <div className="row">
            <div className="text-center">
              <h6 className="subheading">Testimonial</h6>

              <div
                style={{ color: "black" }}
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-offset="30%"
                className="d-flex flex-column gap-1 gap-lg-3 align-items-center justify-content-center mt-3 mt-xxl-5"
              >
                <h1 className="me-3">Client </h1>
                <h1 className="characterbackground text-center ">
                  <div className="circle-spacing">F</div>
                  <span>eedback</span>
                </h1>
              </div>
            </div>

            <div className="d-lg-flex align-items-center justify-content-between">
              <button
                onClick={() => sliderRef.current.slickPrev()}
                className="slider-button d-none d-lg-block"
              >
                <AiOutlineArrowLeft />
              </button>
              <div className="slider-section">
                <Slider ref={sliderRef} {...settings} className="mt-5">
                  {quotes.map((item, index) => (
                    <div
                      className="px-2"
                      style={{ height: "100%" }}
                      key={index}
                    >
                      <div
                        className="testimonial-box d-flex flex-column justify-content-between"
                        style={{ minHeight: "300px" }}
                      >
                        <div>
                          <img
                            loading="lazy"
                            src={Coomas}
                            width={35}
                            alt="quote"
                          />
                          <h5 className="mt-4 font-face-Syne-Bold">
                            {item.quote}
                          </h5>
                        </div>

                        <div>
                          <h5 className="author mt-xxl-4 p-0 mb-0">
                            <CustomLineText text={item.author} color="black" />
                          </h5>
                          <p className="font-face-Syne-Bold ps-5 py-0 m-0">
                            {item?.company}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
              <div className="d-flex justify-content-end justify-content-lg-start gap-2 gap-lg-0 pb-5 pb-lg-0">
                <button
                  onClick={() => sliderRef.current.slickPrev()}
                  className="slider-button d-lg-none"
                >
                  <AiOutlineArrowLeft />
                </button>
                <button
                  onClick={() => sliderRef.current.slickNext()}
                  className="slider-button"
                >
                  <AiOutlineArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeedBack;
