import React, { useState } from "react";
import HeroBanner from "../components/HeroBanner";
import ServicesCard from "../components/ServicesCard";
import ClientsExperience from "../components/ClientsExperience";
import Appdevelopment from "../assets/images/development-banner.png";
import ContactForm from "../components/ContactForm";
import OurExpertise from "../components/OurExpertise";
import BuildWithTeams from "../components/BuildWithTeams";
import DigitalServices from "../components/DigitalServices";
import OurProcessOperation from "../components/OurProcessOperation.js";
import Platform1 from "../assets/images/inner-pages-images/custom-development-1.png";
import Platform2 from "../assets/images/inner-pages-images/custom-development-2.svg";
import SolutionOverviewImg from "../assets/images/inner-pages-images/custom-development-3.png";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import SolutionOverview from "../components/SolutionOverview";
import FeedBack from "../components/FeedBack.js";
import ReadyToBuild from "../components/ReadyToBuild.js";

function MobileAppDevelopmentPage() {
  const bannerData = {
    pageName: "Custom Development",
    description: [
      "In today’s evolving digital world, businesses need solutions that are not just functional but also adaptable. At Sixlogs Technologies, we deliver custom development services designed to meet unique business needs, ensuring efficiency, flexibility, and long-term success. Our expertise spans strategic planning, advanced engineering, and continuous optimization to create solutions that evolve with your business.",
      "We go beyond traditional development by understanding your objectives and aligning our solutions to drive measurable results. By analyzing industry trends, user behavior, and emerging technologies, we develop robust, scalable, and secure digital solutions. Every element is fine-tuned for seamless integration, high performance, and sustained growth.",
    ],
    imgsrc: Appdevelopment,
  };

  const serviceData = [
    {
      title: "Strategic Planning",
      description:
        "Tailored development strategies ensure long-term scalability. We define key features, optimize performance, and align solutions with business goals.",
    },
    {
      title: "User-Centric Design",
      description:
        "Intuitive interfaces enhance usability and engagement. Our expert designers create seamless UI/UX experiences that improve interaction.",
    },
    {
      title: "Robust Development",
      description:
        "High-quality coding ensures security and reliability. We develop scalable and maintainable architectures for long-term efficiency.",
    },
    {
      title: "Seamless Integration",
      description:
        "API and cloud solutions enhance connectivity. We implement smooth data synchronization for automation and streamlined operations.",
    },
    {
      title: "Quality Assurance",
      description:
        "Comprehensive testing eliminates risks. We conduct functional, security, and performance evaluations for flawless execution.",
    },
    {
      title: "Ongoing Support",
      description:
        "Proactive monitoring ensures optimal performance. We provide security updates, performance enhancements, and continuous technical support.",
    },
  ];

  const platformData = {
    pageName: "custom-app-development",
    imgsrc: Platform1,
    title: "Adaptive Solutions – Limitless Potential",
    description:
      "At Sixlogs Technologies, we create next-generation digital solutions designed for performance, scalability, and seamless user experiences. Our development process ensures adaptability to evolving business needs, delivering high-performing, future-proof applications.",
    subheading: "Our services include:",

    unOrderData: [
      {
        ulHeading: "Concept to Execution:",
        ulDescription:
          "Turning ideas into fully operational digital solutions.",
      },
      {
        ulHeading: "Optimized Performance:",
        ulDescription:
          "Enhancing speed, responsiveness, and system efficiency.",
      },
      {
        ulHeading: "Custom Features:",
        ulDescription:
          "Tailor-made functionalities to meet specific business requirements.",
      },
      {
        ulHeading: "Cross-Platform Development:",
        ulDescription:
          "Seamless operation across web, mobile, and desktop environments.",
      },
      {
        ulHeading: "Security & Compliance:",
        ulDescription:
          "Implementing strict data protection and regulatory adherence.",
      },
      {
        ulHeading: "Scalability Planning:",
        ulDescription: "Future-proofing solutions for sustainable growth.",
      },
    ],
  };

  const lightningData = {
    pageName: "custom-app-development",
    imgsrc: Platform2,
    title: "Flexible Technologies – Continuous Growth",
    description:
      "We develop high-performance, adaptable solutions that enable businesses to scale effortlessly. Our agile methodologies ensure rapid deployment, seamless integration, and long-term success.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "Adaptive Frameworks:",
        ulDescription: "Flexible architectures that evolve with your business.",
      },
      {
        ulHeading: "Cloud-Powered Solutions:",
        ulDescription:
          "Secure and scalable cloud infrastructure for maximum efficiency.",
      },
      {
        ulHeading: "Performance Optimization:",
        ulDescription: "Speed, reliability, and user engagement enhancements.",
      },
      {
        ulHeading: "Data Security & Compliance:",
        ulDescription: "Robust security protocols for data protection.",
      },
      {
        ulHeading: "Cross-Platform Functionality:",
        ulDescription: "Consistent performance across digital ecosystems.",
      },
      {
        ulHeading: "Expert Support & Upgrades:",
        ulDescription: "Continuous improvements and proactive maintenance.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "custom-app-development",
    title: "Crafting Apps That Elevate Your Business",
    imgsrc: SolutionOverviewImg,
    description: [
      "At Sixlogs Technologies, we don’t just build apps—we create digital experiences tailored to your vision. Our custom app development process is designed to turn complex challenges into intuitive, high-performance solutions that seamlessly align with your business goals.",
      "Every application we develop is built for agility, ensuring effortless scalability, cross-platform compatibility, and a flawless user experience. Whether it's an enterprise tool or a consumer-facing app, we craft solutions that blend seamless functionality with engaging design.",
      "With a deep focus on precision, security, and long-term adaptability, Sixlogs Technologies transforms your ideas into powerful applications that redefine how businesses operate in the digital age.",
    ],
  };

  return (
    <div className="">
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      {/* <OurExpertise /> */}
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default MobileAppDevelopmentPage;
