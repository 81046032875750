import React, { useState } from "react";
import HeroBanner from "../components/HeroBanner";
import ServicesCard from "../components/ServicesCard";
import businessBanner from "../assets/images/business-banner.png";
import SolutionOverview from "../components/SolutionOverview";
import ContactForm from "../components/ContactForm";
import Platform1 from "../assets/images/inner-pages-images/business-solution-1.png";
import Platform2 from "../assets/images/inner-pages-images/business-solution-2.png";
import SolutionOverviewImg from "../assets/images/inner-pages-images/business-solution-3.svg";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";

function BusinessSolutionPage() {
  const bannerData = {
    pageName: "Business Solution",
    description: [
      "In today’s fast-paced and ever-evolving business landscape, staying ahead requires more than just keeping up with technology; it demands innovation and strategic implementation. At Sixlogs Technologies, we specialize in delivering tailored business solutions designed to streamline operations, enhance productivity, and drive sustainable growth.",
      "Our comprehensive suite of business solutions leverages cutting-edge tools and strategies to empower your organization. By adapting to evolving market trends and seizing new opportunities, we ensure that your business remains competitive and achieves long-term success. Our approach is holistic, addressing every aspect of your business needs, from operational efficiency to customer engagement.",
    ],
    imgsrc: businessBanner,
  };

  const serviceData = [
    {
      title: "Flexibility & Dynamic",
      description:
        "Sixlogs Technologies designs dynamic solutions that enable businesses to quickly respond to changing market conditions. By integrating scalable technologies and agile methodologies, we ensure that your operations remain adaptable and efficient.",
    },
    {
      title: "Cost Efficiency",
      description:
        "Our cost-efficient IT solutions optimize resource allocation and minimize operational expenses. By leveraging advanced technology and strategic planning, we provide solutions that maximize return on investment while maintaining budget-conscious strategies.",
    },
    {
      title: "Scalability in Future",
      description:
        "Planning for expansion is crucial for success. Our scalable IT solutions allow seamless growth, ensuring that your business infrastructure can adapt to increased demand and new technological advancements effortlessly.",
    },
    {
      title: "Potential Growth",
      description:
        "We help businesses identify and seize new growth opportunities. By analyzing industry trends and applying innovative strategies, Sixlogs Technologies ensures sustainable business expansion and a competitive edge in the marketplace.",
    },
    {
      title: "Valuation Software",
      description:
        "Our advanced valuation software provides precise asset assessments, integrating real-time data and analytics to support strategic planning and financial decision-making with confidence.",
    },
    {
      title: "Listing Platforms",
      description:
        "Our intuitive listing platforms enhance exposure for your products, services, or properties. Designed to drive engagement and increase conversions, our solutions help businesses stand out in a competitive digital marketplace.",
    },
  ];

  const [activeService, setActiveService] = useState({
    title: "Android Development",
    description:
      "At Sixlogs Technologies, we specialize in creating high-performance Android applications that cater to your business's unique needs. Our Android development team is proficient in leveraging the latest technologies and frameworks to build apps that are not only functional but also engaging and user-friendly. From initial concept to final deployment, we ensure that your app delivers a seamless user experience and meets your strategic objectives. Our Android development process includes thorough market research, user-centric design, robust coding practices, and comprehensive testing to ensure the highest quality. Whether you need a standalone app or an integrated solution that works across multiple platforms, our team is equipped to deliver. We focus on creating scalable and secure Android applications that can grow with your business and provide long-term value.",
  });
  const digitalservices = [
    {
      title: "Android Development",
      description:
        "At Sixlogs Technologies, we specialize in creating high-performance Android applications that cater to your business's unique needs. Our Android development team is proficient in leveraging the latest technologies and frameworks to build apps that are not only functional but also engaging and user-friendly. From initial concept to final deployment, we ensure that your app delivers a seamless user experience and meets your strategic objectives. Our Android development process includes thorough market research, user-centric design, robust coding practices, and comprehensive testing to ensure the highest quality. Whether you need a standalone app or an integrated solution that works across multiple platforms, our team is equipped to deliver. We focus on creating scalable and secure Android applications that can grow with your business and provide long-term value.",
    },
    {
      title: "iOS Development",
      description:
        "At Sixlogs Technologies, we create feature-rich iOS applications tailored to your business needs. Our team excels in delivering innovative and secure solutions that elevate user experience and brand value. From app design to deployment, we ensure a smooth and engaging iOS app journey.",
    },
    {
      title: "Hybrid Development",
      description:
        "Our hybrid development services combine the best of native and web technologies. We build cross-platform apps that provide a seamless user experience while saving time and cost. Perfect for businesses looking for a flexible, scalable solution.",
    },
    {
      title: "Web App Development",
      description:
        "Sixlogs Technologies specializes in creating dynamic, responsive, and user-friendly web applications. Our solutions are tailored to meet your business goals, offering high performance, scalability, and security.",
    },
    {
      title: "Wearable Development",
      description:
        "Stay ahead of the curve with our cutting-edge wearable development services. We build intuitive and innovative applications for wearable devices, ensuring an exceptional user experience that enhances everyday life.",
    },
    {
      title: "App Modernization",
      description:
        "Modernize your legacy applications with Sixlogs Technologies. We revamp outdated systems to meet current standards, improve functionality, and enhance performance, ensuring your business stays competitive.",
    },
  ];
  const platformData = {
    pageName: "business-solution",
    imgsrc: Platform1, // Ensure Platform1 is properly imported or defined
    title: "Drive Business Growth with Digital Innovation",
    description:
      "At Sixlogs Technologies, we help businesses embrace digital transformation by integrating advanced technologies into their operations. Our expertise ensures seamless transitions, optimizing efficiency, and fostering innovation. We leverage AI, cloud computing, and IoT to modernize businesses and enhance digital experiences.",
    subheading: "Our digital transformation services include:",

    unOrderData: [
      {
        ulHeading: "Tailored Strategies:",
        ulDescription:
          "We develop customized digital solutions that optimize IT infrastructure, improve data management, and enhance customer engagement.",
      },
      {
        ulHeading: "Seamless Implementation:",
        ulDescription:
          "Our experts implement AI, cloud computing, and IoT-driven solutions for smooth transitions to data-driven processes.",
      },
      {
        ulHeading: "Competitive Edge:",
        ulDescription:
          "We ensure businesses stay agile, competitive, and future-ready by aligning digital transformation with strategic goals.",
      },
    ],
  };

  const lightningData = {
    pageName: "business-solution",
    imgsrc: Platform2,
    title: "Strategic Roadmaps for Product Success",
    description:
      "At Sixlogs Technologies, we create detailed product roadmaps that align teams, define priorities, and guide development. Our expertise ensures that your product vision translates into actionable steps, delivering clarity and focus throughout the process.",
    subheading: "Our product roadmapping services include:",

    unOrderData: [
      {
        ulHeading: "Agile Planning:",
        ulDescription:
          "We collaborate to establish objectives, set timelines, and adapt roadmaps to market changes with agile methodologies.",
      },
      {
        ulHeading: "Execution & Alignment:",
        ulDescription:
          "Our roadmaps serve as communication tools, keeping stakeholders informed and tracking development progress.",
      },
      {
        ulHeading: "Strategic Growth:",
        ulDescription:
          "We help manage resources effectively, ensuring product success through meticulous planning and execution.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "business-solution",
    title: "Innovative & High-Quality Software Solutions",
    imgsrc: SolutionOverviewImg,
    description: [
      "At Sixlogs Technologies, we take pride in being a leading provider of cutting-edge software solutions. Our commitment to innovation ensures that we deliver high-quality, customized solutions tailored to meet the unique needs of businesses across various industries. By leveraging the latest technologies, we help organizations enhance efficiency, scalability, and overall performance.",
      "Our team of experts specializes in end-to-end software development, managing everything from ideation to deployment. We focus on creating seamless and future-ready solutions that align with industry trends and business objectives. Through our structured approach, we ensure precision in execution, delivering software that drives measurable results.",
      "At Sixlogs Technologies, we stay at the forefront of technological advancements, integrating modern frameworks and tools to build powerful, scalable applications. Our forward-thinking approach enables businesses to adopt digital transformation strategies that maximize growth and efficiency.",
    ],
  };

  return (
    <div>
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      {/* <OurExpertise /> */}
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default BusinessSolutionPage;
