import React, { useEffect } from "react";

function PrivacyPolicyPage() {

    return (
        <div className="container py-5">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="d-flex flex-column">
                        <h1 className="font-face-Syne-Bold">Privacy & Policy</h1>
                    </div>

                    <div className="text-black">
                        <p className="">
                            Give your visitor a smooth online experience with a solid UX
                            design
                        </p>
                        <p className="m-0 py-2 text-justify font-face-DMSans-Regular">
                            This Privacy Policy applies to information collected by or on
                            behalf of Sixlogs, Inc. and its affiliates and subsidiaries
                            (collectively, “Sixlogs”, “we”, “us”, “our”), including, but
                            not limited to, Personal Information collected through our
                            websites at sunbeltrentals.com and sunbeltgear.com, and mobile
                            versions of those websites (collectively, the “Site”), in store,
                            by phone, our mobile application, and anywhere else we display
                            this Privacy Policy (collectively with the Site, the “Platforms”).
                            This Privacy Policy outlines the information we collect, how we
                            use and share that information, and your rights and choices with
                            regard to personal information we collect. Please be sure to read
                            this entire Privacy Policy and our Terms and Conditions of Use
                            before using or submitting information via the Platforms.
                        </p>

                        <ol>
                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b > Collection of Information: “Personal Information”</b>  means
                                information that directly identifies or can be used to identify
                                you as an individual. When you visit or use any of the
                                Platforms, or services we may collect your Personal Information.
                                Some of this Personal Information is required in order to allow
                                you to use all of the features of the Platforms (for example, in
                                order to rent equipment, you will need to provide us with
                                certain Personal Information). Other information is submitted by
                                you voluntarily. For example, we may collect:
                                <p className="font-face-DMSans-Regular">
                                    <b > Contact information</b> such as your name, job title, address,
                                    email address, telephone number, including mobile number and
                                    fax number
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Identification or verification</b> information such as driver’s
                                    license, date of birth and federal tax identification number
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Payment information</b> such as your billing address and payment
                                    methods
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b >Banking information and Social Security Number</b> if you use our
                                    credit services, as required to process your application
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Transaction history</b> such as purchase information, order
                                    history, and product interests
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b >Communications information</b> such as email messages, voice
                                    recordings, call transcripts and information that you share
                                    when you call our customer service line and SMS records and
                                    message content
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Feedback</b> such as survey responses and comments you submit to
                                    us
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b >Browsing and device information</b> using various technologies
                                    such as cookies, Internet tags and navigational data, such as
                                    the URL you just visited, the browser version your computer is
                                    operating, the date and time you visited, the pages you
                                    visited on the Site and your IP address. “Cookies” are tiny
                                    alphanumeric identifiers that are stored on your computer and
                                    allow us to adjust the Site to meet your personal browsing
                                    preferences. If your browser is set to refuse cookies, you may
                                    not have access to all areas or features of the Site.
                                </p>
                            </li>
                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b >How We Collect Your Information:</b> We may collect information
                                directly from you, automatically via your use of the Platforms,
                                or from third-party sources. You may provide information to us
                                when you: request services and products through a Platform (such
                                as through our Contact Us page or renting equipment); register
                                an account with us; complete a survey; contact customer service;
                                or complete a credit application. We may collect information
                                automatically when you visit the Platforms. We may collect
                                information via cookies or internet tags or analytics tools.{" "}
                            </li>
                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b >  How We Use Your Information:</b> Gathering Personal Information
                                about our customers allows us to serve you better. We may use
                                your information to accomplish our business purposes including:
                                for our everyday business purposes, such as to provide services
                                to you, to extend credit to you, to market our products and
                                services to you, to communicate with you, to maintain your
                                account with us, and to administer our business (e.g., to secure
                                or make improvements to the Platforms and prevent fraud).{" "}
                                <p className="font-face-DMSans-Regular">
                                    We or our service providers may aggregate, evaluate and use
                                    browsing and device information to improve the Platforms and
                                    our business. We may use IP addresses or other browsing and
                                    device information to analyze trends, administer the
                                    Platforms, track customer navigation on a Platform and gather
                                    aggregated demographic information.
                                </p>
                            </li>

                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b >  How We Share Your Information:</b>
                                <p className="font-face-DMSans-Regular">
                                    <b >Service Providers.</b> From time to time, we may employ companies
                                    and individuals to perform certain services on our behalf
                                    (such as conducting audits, performing legal services,
                                    processing credit card payments, providing customer service,
                                    providing customer database management services, providing
                                    email services, providing marketing services, processing
                                    credit applications). These third-party service providers may
                                    have access to Personal Information, but only to the extent
                                    required to perform their assigned roles and they may not use
                                    your Personal Information for any other reason.
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Business Transfers.</b> As our business continues to evolve, we
                                    may wish to sell or buy subsidiaries or business units. In
                                    such transactions, customer information (including Personal
                                    Information) generally is one of the transferred business
                                    assets but remains subject to the terms of any pre-existing
                                    Privacy Policy.
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Compliance with Law.</b> We may release Personal Information about
                                    you if we believe such a release is necessary to comply with
                                    the law or in response to a subpoena or subject to other legal
                                    process or as needed to protect our rights.
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b > Related Corporate Companies.</b> We may share your Personal
                                    Information with companies related to us by common ownership
                                    or control for business purposes.
                                </p>
                                <p className="font-face-DMSans-Regular">
                                    <b >Other Third Parties.</b> We may also share your Personal
                                    Information with third parties with whom we have joint
                                    marketing arrangements to market other products and services
                                    to you.
                                </p>
                            </li>
                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b >Protection of Your Information:</b>  To prevent unauthorized access to, maintain data accuracy and ensure the correct use of information, we have put in place commercially reasonable physical, electronic and managerial procedures to safeguard and secure the information we collect online. However, no security system is perfect, and we cannot guarantee that information you provide to us over the Internet will remain private. We also cannot guarantee that information you transmit to us over the Internet will not be intercepted.
                            </li>
                            <li className="font-face-DMSans-Regular" style={{ color: "rgb(70, 70, 70)" }}>
                                <b >Do-Not-Track & Third Party Tracking Disclosures:</b>  Some websites have “do not track” features that allow you to tell a website not to track you. These features are not all uniform. The Site does not currently respond to browser based “do not track” signals. To learn more about your choices regarding this type of data collection or to opt-out of interest-based advertising by members of the NAI or the DAA, please visit https://www.aboutads.info/choices or https://www.aboutads.info/choices.
                            </li>
                        </ol>

                        <p className="font-face-DMSans-Regular">
                            We contract with service providers to serve or send advertisements on our behalf. We work with these advertising service providers that collect information across various channels, including offline and online, for purposes of delivering more relevant advertising to you. Our advertising providers may place or recognize a cookie on your computer, device, or directly in our emails and communications, and we may share Personal Information with them if you have submitted such information to us. These service providers may link the anonymous information we share with them to the cookie stored on your browser or device and collect information such as your IP address, browser or operating system type and version, and demographic or purchase information.
                        </p>

                        <p className="font-face-DMSans-Regular">
                            We do not permit third parties to collect personal information through cookies or other tools on our Platforms concerning your online activities over time across different websites for the third parties’ own purposes. Most web browsers automatically accept cookies but you can usually modify your browser settings to decline cookies but doing so may prevent you from accessing all functionality of the Platforms.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;
