import React, { useEffect } from "react";
import SpecialtiesSection from "../components/SpecialtiesSection";
import OurExpertise from "../components/OurExpertise";
import InfoCards from "../components/InfoCards";
import SeeMore from "../components/SeeMore";
import ContactForm from "../components/ContactForm";
import Banner from "../components/Banner";
import MarqueeCard from "../components/MarqueeCard";
import { useLocation } from "react-router-dom";

const HomePage = ({ setHover }) => {
  const Heading = "way";
  const SubHeading = "Resources";
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Banner setHover={setHover} />
      <SpecialtiesSection />
      <MarqueeCard />
      <OurExpertise />
      <InfoCards Heading={Heading} SubHeading={SubHeading} />
      <SeeMore />
      <ContactForm />
    </div>
  );
};

export default HomePage;
