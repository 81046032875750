import React, { useEffect, useState } from "react";
import "./HeroBanner.css";

function HeroBanner({ data }) {
  const { pageName, description, imgsrc } = data;
  const [heading, setHeading] = useState("");
  useEffect(() => {
    setHeading(
      pageName == "AgentForce" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">A</div>
            <span>gentForce</span>
          </h1>
        </>
      ) : pageName == "Business Solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>olution</span>
          </h1>
        </>
      ) : pageName == "Custom Development" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">D</div>
            <span>evelopment</span>
          </h1>
        </>
      ) : pageName == "Digital Marketing" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">M</div>
            <span>arketing</span>
          </h1>
        </>
      ) : pageName == "IT-Staff Augmentation" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">A</div>
            <span>ugmentation</span>
          </h1>
        </>
      ) : pageName == "IT Solutions" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <span className="me-2 me-md-3">IT</span>
            <div className="circle-spacing">S</div>
            <span>olution</span>
          </h1>
        </>
      ) : pageName == "HubSpot Solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>olution</span>
          </h1>
        </>
      ) : (
        <></>
      )
    );
  }, []);

  return (
    <>
      <div className="herobanner">
        <div className="custom-container">
          <div className="row py-3 py-md-5 gap-5 gap-md-0 d-flex justify-content-between align-items-center">
            <div className="col-lg-6 d-flex flex-column order-2 order-lg-1">
              <h1
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-offset="30%"
                className="characterbackground"
              >
                {pageName !== "IT Solutions" && pageName !== "AgentForce" && pageName?.split(" ")[0]}
              </h1>
              {heading}
              {description.map((item, index) => (
                <p key={index} className="desc mt-2 mt-xxl-4">
                  {item}
                </p>
              ))}
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center order-1 order-lg-2">
              <img loading="lazy" src={imgsrc} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroBanner;
