import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-cenario-1.png";
import explore2 from "../assets/images/explore/explore-cenario-2.png";
import exploreMap from "../assets/images/explore/explore-cenario-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import "./MusikarExplorePage.css";
import AngularJsIcon from "../assets/images/explore/AngularJsIcon.svg";

function CenarioExplorePage() {
  const bannerData = {
    heading: "Transforming Operations",
    description:
      " See how a top organization utilized Cenario to streamline operations, optimize workflows, and enhance system integrations. With expert consulting across Salesforce, Android, iOS, and HubSpot, they tackled operational challenges and achieved measurable business growth. ",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Cenario is an AI-powered decision intelligence platform designed to streamline workflows, automate processes, and optimize business operations with data-driven insights.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "AI-Powered Insights",
        "Process Automation",
        "Business Optimization",
        "System Integration",
      ],
    },
    scope: {
      heading: "Scope",
      description: "App redesign",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "App Development",
        "Workflow Automation",
        "AI Insights",
        "System Optimization",
        "QA & Testing",
        "Deployment & Support",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Organizations often struggle with process inefficiencies and data-driven decision-making. The client wanted an intelligent platform that automates workflows, optimizes operations, and leverages AI-powered insights to drive business success.",
    heading2: "About the Product",
    description2:
      "Cenario is an AI-powered decision intelligence platform that automates processes, optimizes workflows, and enhances business performance. It integrates predictive analytics and real-time insights, helping organizations achieve smarter decision-making and sustainable growth.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "AI-Powered Decision Support – Optimize business choices with intelligence.",
      "Workflow Automation – Reduce manual tasks with smart automation.",
      "Data-Driven Forecasting – Predict trends and optimize operations.",
      "Seamless API Integrations – Connect with enterprise software solutions.",
      "Real-Time Business Insights – Visualize performance metrics dynamically.",
      "Scenario Planning – Simulate outcomes for informed decision-making.",
      "Customizable KPI Dashboards – Track key business performance indicators.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
      {
        img_src: AngularJsIcon,
        description:
          "We use Angular as a powerful front-end framework that enables the development of dynamic and scalable web applications.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default CenarioExplorePage;
