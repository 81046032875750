import React, { useEffect, useRef } from "react";
import "./SpecialtiesSection.css";
import arrowright from "../assets/images/arrowright.svg";
// import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";
import specialitiesIcon1 from "../assets/images/specialities/specialitiesIcon1.svg";
import specialitiesIcon2 from "../assets/images/specialities/specialitiesIcon2.svg";
import specialitiesIcon3 from "../assets/images/specialities/specialitiesIcon3.svg";
import specialitiesIcon4 from "../assets/images/specialities/specialitiesIcon4.svg";
import specialitiesIcon5 from "../assets/images/specialities/specialitiesIcon5.svg";
import specialitiesIcon7 from "../assets/images/specialities/specialitiesIcon7.svg";
import AIIcon from "../assets/images/specialities/AIIcon.svg";
import AICardGrid from "../assets/images/AICardGrid.svg";
import speclitiesbgImg from "../assets/images/speclitiesbgImg.svg";
import AboutCompanylogo2 from "../assets/videos/Logo2.mp4";
import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";
import CustomButton from "./custom/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomLineText from "./custom/CustomLineText";

const SpecialtiesSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.error("Autoplay error:", error);
          });
      }
    }
  }, []);
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const data = [
    {
      img_src: specialitiesIcon1,
      heading: "Salesforce Consulting",
      href: "/salesforce-services",
      description:
        "Salesforce consulting streamlines operations, boosts engagement, and drives business success through digital solutions.",
    },
    {
      img_src: specialitiesIcon7,
      heading: "HubSpot",
      href: "/hubspot",
      description:
        "HubSpot consulting optimizes workflows, enhances engagement, and accelerates growth through inbound marketing solutions.",
    },
    {
      img_src: specialitiesIcon2,
      heading: "Business Solution",
      href: "/business-solution",
      description:
        "Business solutions optimize processes, improve productivity, and help companies succeed in a digital world.",
    },
    {
      img_src: specialitiesIcon3,
      heading: "Custom Development",
      href: "/custom-development",
      description:
        "Custom development improve functionality, streamline workflows, and offer tailored solutions for business efficiency.",
    },
    {
      img_src: specialitiesIcon4,
      heading: "Staff Augmentation",
      href: "/staff-augmentation",
      description:
        "Staff augmentation provides skilled professionals, enhancing flexibility and boosting efficiency in competitive markets.",
    },
    {
      img_src: specialitiesIcon5,
      heading: "Digital Marketing",
      href: "/digital-marketing",
      description:
        "Digital marketing boosts brand presence, increases engagement, and drives growth in a competitive environment.",
    },
    // {
    //   img_src: specialitiesIcon6,
    //   heading: "IT Solution",
    //   href: "/it-solution",
    //   description:
    //     "IT solutions enhance infrastructure, ensure security, and support seamless digital transformation for business success.",
    // },
  ];

  return (
    <div id="services" className="SpecialtiesSection bg-white">
      <div className="bg-img">
        <img loading="lazy" src={speclitiesbgImg} alt="" />
      </div>
      <div className="main-div">
        <div className="custom-container">
          <div className="d-flex flex-column flex-md-row align-items-md-center gap-3 gap-lg-5">
            <div className="d-flex flex-column col-md-7 col-lg-5 heading-container p-0">
              <CustomLineText text="Services" />

              <div className="d-lg-flex gap-3 align-items-lg-center">
                <h1
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-offset="30%"
                  className="characterbackground"
                >
                  <div className="circle-spacing">O</div>
                  <span>ur Specialties</span>
                </h1>
              </div>
            </div>
            <div className="col-md-5 ">
              <p className="para font-face-DMSans-Regular">
                Synergistically seize front-end methods of empowerment without
                extensive core competencies. Progressively repurpose alternative
                platforms{" "}
              </p>
            </div>
          </div>
          <div className="d-flex flex-column flex-xl-row gap-3 w-100 mt-3 mt-md-5">
            <div
              onClick={() => {
                navigateToPage("/ai");
              }}
              className="cardBox2 mh-100"
            >
              <img loading="lazy" src={AICardGrid} alt="" className="bg-img" />
              <div className="text-white w-100 h-100">
                <div className="d-flex flex-column ">
                  <div className="d-flex justify-content-end align-items-end">
                    <img loading="lazy" src={arrowuprighwhitet} alt="" />
                  </div>
                  <div className="d-flex my-2 my-md-4">
                    <img loading="lazy" src={AIIcon} className="img-fluid" alt="" />
                  </div>
                  <h2>AI & LLM</h2>
                </div>
                <h3 className=" mt-3 mt-xl-4 font-face-DMSans-Regular">
                  A strong and engaging online presence is essential for success
                  in current competitive digital age.
                </h3>
              </div>
            </div>
            <div className="Box">
              <div className="d-flex flex-wrap gap-4 ">
                {data?.map((item, index) => (
                  <div
                    onClick={() => navigateToPage(item?.href)}
                    key={index}
                    className="cardBox"
                  >
                    <div className=" d-flex gap-3 align-items-center justify-content-between">
                      <div className="d-flex col-8 gap-2 align-items-center ">
                        <div className="icon-div d-flex align-items-center justify-content-center">
                          <img loading="lazy"
                            src={item?.img_src}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h4>{item?.heading}</h4>
                      </div>
                      <div className="col-2">
                        <img loading="lazy" src={arrowright} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <p className="para mt-3 font-face-DMSans-Regular">
                      {item?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="AboutCompany pb-5 pb-md-3">
          <div className="custom-container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <video
                  ref={videoRef}
                  muted
                  playsInline
                  loop
                  controls={false}
                  style={{ width: "100%" }}
                >
                  <source src={AboutCompanylogo2} type="video/mp4" />
                </video>
              </div>
              <div className="col-lg-6">
                <div className="d-flex flex-column gap-3 ">
                  <CustomLineText text="About Us" />
                  <div>
                    <div
                      data-aos="fade-up"
                      data-aos-anchor-placement="bottom-bottom"
                    >
                      <h1 className="characterbackground">
                        <div className="circle-spacing">S</div>
                        <span>ixlogs</span>
                      </h1>
                      <h1>Technologies</h1>
                      {/* <h1>Based in USA</h1> */}
                    </div>
                  </div>
                  <p className="para">
                    Our firm is designed to operate as one single partnership
                    united by a strong set of values, including a deep
                    commitment to diversity. We take a consistent approach to
                    recruiting and skills development so that we can quickly
                    deliver the right team, with the right experience and
                    expertise, to every client, every time.
                  </p>
                  <div className="d-flex flex-wrap flex-sm-row w-100 justify-content-between ">
                    <div>
                      <h1>12+</h1>
                      <p className="para">Years of experience</p>
                    </div>
                    <div>
                      <h1>1.2k</h1>
                      <p className="para">Worldwide clients</p>
                    </div>
                    <div>
                      <h1>3.5k</h1>
                      <p className="para">Projects delivered</p>
                    </div>
                  </div>
                  <div>
                    <CustomButton
                      func={() => {
                        navigateToPage("/contact-us");
                      }}
                      btntext={"Let’s talk now"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialtiesSection;
