import React, { useEffect, useState } from "react";
import "./SolutionOverview.css";

function SolutionOverview({ data }) {
  const [heading, setHeading] = useState("");
  useEffect(() => {
    setHeading(
      data?.pageName == "salesforce" ? (
        <>
          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-lg-3 pt-2">
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-offset="30%"
              className="characterbackground"
            >
              <div className="circle-spacing">C</div>
              <span>loud</span>
            </h1>
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-offset="30%"
              className="characterbackground"
            >
              Development
            </h1>
          </div>
        </>
      ) : data.pageName == "business-solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>oftware Solution </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Provider
          </h1>
        </>
      ) : data.pageName == "custom-app-development" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">B</div>
            <span>espoke Digital</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Experiences
          </h1>
        </>
      ) : data.pageName == "it-staff" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>taffing Myths!</span>
          </h1>
          {/* <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Myths
          </h1> */}
        </>
      ) : data.pageName == "digital-marketing" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">D</div>
            <span>igital Growth</span>
          </h1>
          {/* <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Growth
          </h1> */}
        </>
      ) : data.pageName == "it-solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">C</div>
            <span>ompetitive</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Advantage
          </h1>
        </>
      ) : data.pageName == "hub-spot" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">U</div>
            <span>nlocking HubSpot’s </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Full Potential
          </h1>
        </>
      ) : (
        <></>
      )
    );
  }, []);

  return (
    <div className="solutionOverview">
      <div className="main-section">
        <div className="custom-container">
          <div className="row d-flex align-items-center flex-column flex-lg-row">
            <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
              <div>
                {heading}
                <h4 className="pt-4 pb-lg-3">{data?.title}</h4>

                {data?.description?.map((desc, index) => (
                  <p key={index} className="mt-4 pe-lg-5">
                    {desc}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="ps-5">
                <img loading="lazy" src={data.imgsrc} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionOverview;
