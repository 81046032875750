import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

function SetsOurTalent() {
  const { ref, inView } = useInView({ triggerOnce: true });
  return (
    <div className="last-section mt-xl-5 pt-3 pt-xxl-5">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-offset="30%"
        className="d-md-flex gap-2 align-items-center mt-lg-5"
      >
        <h1 className="characterbackground">
          <div>W</div>
          <span>hat sets</span>
        </h1>
        <h1 className="h1">
          <span className="d-lg-block">our Talent Apart?</span>
        </h1>
      </div>

      <p className="mt-2 col-md-11 mt-2">
        Our success is fueled by the exceptional talent we bring on board. From
        day one, we've focused on hiring the top 1% of IT professionals to
        enhance our business and culture. Our rigorous selection process ensures
        that we recruit only the most experienced software developers and
        engineers.
      </p>
      <p className="mt-2 mt-lg-4 col-md-11">
        Our stringent screening guarantees that our team members possess
        expert-level technical skills, fluency in English, outstanding
        references, and top scores across various assessments. This meticulous
        approach enables us to deliver superior solutions and services to our
        clients.
      </p>
      <div className="row d-flex">
        <div className="col-md-7 col-xl-8 mt-2 mt-lg-4">
          <h4>What It Takes to Work for Sixlogs:</h4>
          <ul className="mt-lg-4 d-flex flex-column gap-4">
            <li>Fluent in English</li>
            <li>10+ years experience</li>
            <li>Highly specialized engineers</li>
            <li>Excellent communication skills</li>
          </ul>
        </div>
        <div ref={ref} className="col-md-5 col-xl-4">
          <div className="textgray1">
            {inView && <CountUp end={400} duration={3} />}+
          </div>
          <h6>Projects done</h6>
          <div className="row">
            <div className="col-md-6">
              <div className="textgray">
                {inView && <CountUp end={10} duration={3} />}+
              </div>
              <h6>Years of Experience</h6>
            </div>
            <div className="col-md-6">
              <div className="textgray">
                {inView && <CountUp end={40} duration={3} />}+
              </div>
              <h6>Team Members</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetsOurTalent;
