import React, { useEffect, useRef } from "react";
import "./LearnAboutOurValues.css";
import learnabout from "../assets/images/learnabout.svg";
import officeEvent from "../assets/videos/officeEvent.mp4";

function LearnAboutOurValues() {
  const ValuesVideoRef = useRef(null);

  useEffect(() => {
    const video = ValuesVideoRef.current;

    if (video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.error("Autoplay error:", error);
          });
      }
    }
  }, []);
  return (
    <div className="ourValues">
      <div className="custom-container">
        <div className="maincontent px-3 px-md-4">
          <div className="d-flex flex-column align-items-center col-lg-9 mt-3 mt-xl-5 pt-3 pt-xl-5">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              className="d-flex flex-column align-items-center"
            >
              <h1 className="h1">
                <span className="d-xl-block">Learn about</span>
              </h1>
              <div className="d-md-flex gap-2 align-items-center">
                <h1 className="characterbackground h1">
                  <div
                    style={{
                      padding: "0px 2%",
                      margin: "0px -2%",
                    }}
                  >
                    O
                  </div>
                  <span>ur Values</span>
                </h1>
              </div>
            </div>

            <h3 className="text-center my-3 my-xl-5">
              We are dedicated to fostering a diverse and inclusive environment.
              We strive to cultivate a culture of respect, collaboration, and
              continuous growth. At Sixlogs, we encourage our team members to
              take initiative, embrace change, and seek new opportunities for
              professional development. We value open communication and sharing
              ideas, ensuring that every voice is heard and respected.
            </h3>
          </div>
          <div className="video d-flex justify-content-center mt-2 mt-xl-5 col-lg-9 ">
            <video
              ref={ValuesVideoRef}
              muted
              playsInline
              loop
              controls={false}
              style={{
                width: "100%",
                borderRadius: 25,
                border: "4px solid #00000050",
              }}
            >
              <source src={officeEvent} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearnAboutOurValues;
