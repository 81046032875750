import React from "react";
import marketing from "../assets/images/digital-banner.png";
import HeroBanner from "../components/HeroBanner";
import ServicesCard from "../components/ServicesCard";
import ContactForm from "../components/ContactForm";
import SolutionOverview from "../components/SolutionOverview";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import Platform1 from "../assets/images/inner-pages-images/digital-marketing-1.png";
import Platform2 from "../assets/images/inner-pages-images/digital-marketing-2.png";
import SolutionOverviewImg from "../assets/images/inner-pages-images/digital-marketing-3.png";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
function DigitalMarketingPage() {
  const bannerData = {
    pageName: "Digital Marketing",
    description: [
      "A strong and engaging online presence is essential for success in current competitive digital age. At Sixlogs Technologies, we specialize in transforming your brand's digital footprint through innovative strategies and creative solutions. Our dedicated team of experts works tirelessly to craft compelling narratives and dynamic campaigns that resonate with your audience and drive meaningful engagement.",
      "We believe that every brand has a unique story to tell, and our mission is to help you tell yours in the most impactful way possible. By leveraging the latest technologies and data-driven insights, we ensure your brand not only stands out in a crowded marketplace but also builds lasting connections with customers. Whether you're looking to enhance brand awareness, increase conversions, or foster loyalty, Sixlogs Technologies is your partner in achieving digital marketing excellence.",
      "Partner with Sixlogs Technologies to elevate your brand and experience the power of strategic, results-driven digital marketing.Let us help you unlock your brand's full potential and drive success in the digital age.",
    ],
    imgsrc: marketing,
  };

  const serviceData = [
    {
      title: "Revenue",
      description:
        "Sixlogs Technologies drives measurable revenue growth with targeted digital strategies. We optimize sales funnels, enhance customer retention, and maximize lifetime customer value.",
    },
    {
      title: "Transactions",
      description:
        "Boost transaction volume with Sixlogs Technologies. Our strategies encourage frequent, high-value purchases through personalized marketing and seamless checkout experiences.",
    },
    {
      title: "Leads",
      description:
        "Generate high-quality leads with Sixlogs Technologies. We craft targeted campaigns using landing pages, valuable content, and advanced audience targeting techniques.",
    },
    {
      title: "Website Traffic",
      description:
        "Increase website traffic with Sixlogs Technologies. Our SEO, content marketing, social media, and paid ads strategies drive high-intent visitors to your site.",
    },
    {
      title: "Paid Ads",
      description:
        "Maximize visibility with Sixlogs Technologies' paid advertising. Our PPC campaigns on Google, Facebook, and LinkedIn ensure targeted reach and high ROI.",
    },
    {
      title: "Organic",
      description:
        "Enhance organic traffic with Sixlogs Technologies’ SEO expertise. We optimize content, keywords, and backlinks to boost search rankings and website visibility.",
    },
  ];
  
  const platformData = {
    pageName: "digital-marketing",
    imgsrc: Platform1,
    title: "Strategic Growth – Tailored for Success",
    description:
      "At Sixlogs Technologies, we craft data-driven marketing strategies to maximize revenue and ensure long-term success. Our approach focuses on personalized solutions that align with your unique business objectives.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "Custom Strategies:",
        ulDescription:
          "We analyze your market landscape to develop tailored digital marketing plans that drive measurable results.",
      },
      {
        ulHeading: "Growth-Focused Partnership:",
        ulDescription:
          "More than a service provider, we collaborate closely with you to identify opportunities and implement impactful strategies.",
      },
      {
        ulHeading: "Actionable Insights & Reporting:",
        ulDescription:
          "With in-depth analytics, we refine campaigns for continuous improvement and sustained growth.",
      },
    ],
  };

  const lightningData = {
    pageName: "digital-marketing",
    imgsrc: Platform2,
    title: "Optimized Strategies – Maximum Impact",
    description:
      "Success in digital marketing starts with a well-defined, customized approach. We ensure every campaign is data-backed, audience-focused, and results-driven.",
    subheading: "Our strategy includes:",

    unOrderData: [
      {
        ulHeading: "Personalized Approach:",
        ulDescription:
          "Every business is unique, so we tailor marketing strategies to meet your specific goals.",
      },
      {
        ulHeading: "Dedicated Growth Support:",
        ulDescription:
          "We act as an extension of your team, ensuring seamless execution and business expansion.",
      },
      {
        ulHeading: "Performance Analytics:",
        ulDescription:
          "Real-time tracking and reporting provide actionable insights to optimize every campaign.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "digital-marketing",
    title: "Modernize your business with Cloud Solutions",
    imgsrc: SolutionOverviewImg,
    description: [
      "At Sixlogs Technologies, we utilize advanced digital marketing strategies to strengthen brand presence, attract the right audience, and drive revenue growth. Our tailored approach ensures that every campaign aligns with your business objectives, delivering measurable success and long-term impact.",
      "Success in digital marketing requires more than just visibility—it demands a strategic partnership. We collaborate closely with businesses to create data-driven campaigns that enhance engagement, build customer trust, and generate sustained growth. By integrating creativity with analytics, we ensure that each marketing effort delivers tangible results.",
      "Optimization is at the core of our strategy. Through continuous monitoring and performance analysis, we refine campaigns to maximize efficiency and return on investment. Our commitment to constant improvement ensures that your digital marketing efforts remain impactful, adaptable, and ahead of industry trends.",
    ],
  };
  return (
    <div>
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      {/* <GetMoreRevenue /> */}
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default DigitalMarketingPage;
