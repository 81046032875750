import React from "react";

function WhySixlogs() {
  return (
    <div className="mt-md-5">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-offset="30%"
        className="d-md-flex gap-2 align-items-center mt-lg-5"
      >
        <h1 className="characterbackground">
          <div>W</div>
          <span>hy Sixlogs</span>
        </h1>
        <h1 className="h1">
          <span className="d-lg-block">Technologies?</span>
        </h1>
      </div>
      <p className="col-lg-10">
        At Sixlogs Technologies, we believe that exceptional talent drives
        exceptional results. Our commitment to excellence is built on the
        foundation of the industry's top 1% of IT talent. We provide
        comprehensive, end-to-end technology solutions crafted by our
        meticulously vetted, expert-level teams.
      </p>
      <div className="row justify-content-center my-3 my-xl-5 py-md-3 ">
        <div className="col-lg-4 mt-4">
          <div className="box text-center">
            <h2 className="font-face-Syne-Bold">Top 1% of IT Talent</h2>
            <span>
              We thrive on finding the Top 1% of IT Talent to fulfill the needs
              of every project. Our team evaluates over 1.2 million applicants
              every year to find and train the most talented Software Engineers
              in Latin America
            </span>
          </div>
        </div>
        <div className="col-lg-4 mt-4">
          <div className="box text-center ">
            <h2 className="font-face-Syne-Bold">Time Zone Alignment</h2>
            <span>
              Our global presence enables us to offer seamless,
              time-zone-aligned services, ensuring effective collaboration and
              support whenever you need it.
            </span>
          </div>
        </div>
        <div className="col-lg-4 mt-4">
          <div className="box text-center ">
            <h2 className="font-face-Syne-Bold">Experienced Teams</h2>
            <span>
              Not only are all of our Senior Engineers fluent in English and
              certified in every major technology and methodology, they also
              have an average of 10 years practical experience. This significant
              knowledge base, coupled with extensive Sixlogs training.
            </span>
          </div>
        </div>
      </div>
      <h3 className="mt-5 col-lg-8">
        Choose Sixlogs Technologies for unparalleled expertise, seamless
        integration, and transformative results.
      </h3>
    </div>
  );
}

export default WhySixlogs;
