import React, { useEffect, useState } from "react";
import "./PlatformDevelopment.css";
function PlatformDevelopment({ data }) {
  const [heading, setHeading] = useState("");
  useEffect(() => {
    setHeading(
      data.pageName == "salesforce" ? (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-lg-3">
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">P</div>
            <span>latform</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Development
          </h1>
        </div>
      ) : data.pageName == "business-solution" ? (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-lg-3">
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">D</div>
            <span>igital</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Transformation
          </h1>
        </div>
      ) : data.pageName == "custom-app-development" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">N</div>
            <span>ext-Gen</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Development
          </h1>
        </>
      ) : data.pageName == "it-staff" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>killed</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Professionals
          </h1>
        </>
      ) : data.pageName == "digital-marketing" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">R</div>
            <span>
              evenue <span className="show-upto">Growth</span>
            </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Growth
          </h1>
        </>
      ) : data.pageName == "it-solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>
              calable <span className="show-upto">Solutions</span>
            </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Solutions
          </h1>
        </>
      ) : data.pageName == "hub-spot" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">H</div>
            <span>
              ubSpot <span className="show-upto">Experts</span>
            </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Solutions
          </h1>
        </>
      ) : (
        <></>
      )
    );
  }, []);

  return (
    <div className="platform">
      <div className="mainsection">
        <div className="custom-container ">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
              <div className="pe-5">
                {heading}
                <h4 className="my-4">{data.title}</h4>
                <p>{data.description}</p>
                <div>
                  <p>{data.subheading}</p>
                  <ul>
                    {data.unOrderData.map((unorder, index) => (
                      <li key={index} className="pt-1">
                        <b>{unorder.ulHeading}</b> {unorder.ulDescription}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="ps-5">
                <img loading="lazy" src={data.imgsrc} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlatformDevelopment;
