import React from "react";
import specialitiesIcon1 from "../assets/images/specialities/specialitiesIcon1.svg";
import specialitiesIcon2 from "../assets/images/specialities/specialitiesIcon2.svg";
import specialitiesIcon3 from "../assets/images/specialities/specialitiesIcon3.svg";
import specialitiesIcon4 from "../assets/images/specialities/specialitiesIcon4.svg";
import specialitiesIcon5 from "../assets/images/specialities/specialitiesIcon5.svg";
import specialitiesIcon6 from "../assets/images/specialities/specialitiesIcon6.svg";
import "./AIExperties.css";

function AIExperties() {
  const data = [
    {
      img_src: specialitiesIcon1,
      heading: "Natural Language",
      description:
        "Understanding and processing human language to drive intelligent and context-aware interactions.",
    },
    {
      img_src: specialitiesIcon2,
      heading: "Computer Vision",
      description:
        "Enabling machines to interpret and analyze visual data with human-like perception.",
    },
    {
      img_src: specialitiesIcon3,
      heading: "Deep Learning",
      description:
        "Advanced neural networks powering data-driven insights, automation, and predictions.",
    },
    {
      img_src: specialitiesIcon4,
      heading: "Machine Learning",
      description:
        "Algorithms that learn from data to enhance decision-making and efficiency.",
    },
    {
      img_src: specialitiesIcon5,
      heading: "Data Science",
      description:
        "Discovering valuable insights from raw data to enhance strategic decision-making.",
    },
    {
      img_src: specialitiesIcon6,
      heading: "LLM",
      description:
        "AI systems trained on extensive datasets to produce human-like responses.",
    },
    {
      img_src: specialitiesIcon5,
      heading: "Custom Models",
      description:
        "Developing specialized AI solutions to solve complex business challenges efficiently.",
    },
    {
      img_src: specialitiesIcon6,
      heading: "Data Management",
      description:
        "Organizing, storing, and optimizing data for seamless accessibility and security.",
    },
  ];

  return (
    <div className="AIExperties py-xxl-5">
      <div className="custom-container py-lg-2">
        <div className="d-flex flex-column align-items-center py-3 py-md-5">
          <h3 className="font-face-Syne-Bold pb-3">AI Services</h3>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="d-flex flex-column align-items-center"
          >
            <h1 className="characterbackground">
              <div className="circle-spacing">O</div>
              <span>ur</span>
            </h1>
            <h1>Expertise</h1>
          </div>
        </div>
        <div className="row">
          {data?.map((item, index) => (
            <div key={index} className="p-3 col-md-6 col-lg-4 col-xl-3 mh-100">
              <div className="cardBox mh-100">
                <div className="row d-flex gap-3 align-items-center justify-content-between">
                  <div className="d-flex gap-2 align-items-center ">
                    {/* <div className="icon-div d-flex align-items-center justify-content-center">
                      <img loading="lazy" src={item?.img_src} alt="" className="img-fluid" />
                    </div> */}
                    <h4>{item?.heading}</h4>
                  </div>
                  {/* <div className="col-2">
                <img loading="lazy" src={arrowright} alt="" className="img-fluid" />
              </div> */}
                </div>
                <p className="para mt-3">{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AIExperties;
