import React, { useRef, useState } from "react";
import "./ContactForm.css";
import MailIcon from "../assets/images/MailIcon.svg";
import CallIcon from "../assets/images/CallIcon.svg";
import locationIcon from "../assets/images/locationIcon.svg";
import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";
import CustomButton from "./custom/CustomButton";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const recaptchaRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("youremail");
    const nameInput = document.getElementById("yourname");
    const messageInput = document.getElementById("yourmessage");
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      messageInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!verified) {
      alert("Please complete the reCAPTCHA verification.");
      return; // Prevent form submission
    }

    const response = await fetch(
      "https://37vv41pax9.execute-api.us-east-1.amazonaws.com/sixlogs_website_contactus",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: "",
          userCompanyName: "",
          industry: "",
          userMessage: message,
        }),
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      alert(responseData.statusText || "Email sent successfully!");
    } else {
      alert("Something went wrong. Please try again.");
    }

    // const responseData = await response.json();
    // alert(response.statusText);
    // console.log(responseData.statusText, "check");
    document.getElementById("myForm").reset();
  };

  function onChange(value) {
    setVerified(true);
  }

  return (
    <div className="ContactForm">
      <div className="custom-container">
        <div className="d-flex flex-column align-items-center">
          <h5 className="font-face-Syne-Bold">Contact</h5>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="top-center"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">L</div>
            <span>et’s Connect</span>
          </h1>
        </div>
        <form onSubmit={handleSubmit} id="myForm">
          <div className="row mt-lg-5">
            <div className="col-lg-4 mt-5 ">
              <input
                placeholder="Your Name*"
                id="yourname"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-lg-4 mt-5 ">
              <input
                placeholder="Your e-mail*"
                id="youremail"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-lg-4 mt-5 ">
              <input
                placeholder="Tell us about your project"
                id="yourmessage"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdIc-wqAAAAAHVUQvr7C-K4UYi79dQFf0uVbzm_"
            onChange={onChange}
            className="mb-4"
          />
          <div className="d-flex justify-content-center py-5">
            <CustomButton
              btntext={"Send"}
              customStyle={{
                padding: "16px 40px",
                borderRadius: 10,
                backgroundColor: "#080808F2",
                fontSize: "clamp(15px, 1.2vw, 20px)",
              }}
            />
          </div>
        </form>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center gap-5">
          <div className="d-flex flex-column align-items-lg-center col-md-4 my-3 my-lg-5">
            <div className="d-flex gap-3">
              <div>
                <img loading="lazy" src={MailIcon} />
              </div>

              <div className="">
                <h3 className="para">Email us</h3>
                <h4>
                  <a
                    href="mailto:info@sixlogs.com"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    info@sixlogs.com
                  </a>
                </h4>
              </div>
            </div>
          </div>
          {/* <div className="d-flex flex-column align-items-lg-center col-lg-4 my-3 my-lg-5">
            <div className="d-flex gap-3">
              <div>
                <img loading="lazy" src={CallIcon} />
              </div>

              <div className="">
                <h3 className="para">Call us</h3>
                <h4 role="button">
                  <a
                    href="tel:+17132132908"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    +1-713-213-2908
                  </a>
                </h4>
              </div>
            </div>
          </div> */}
          <div className="d-flex flex-column align-items-lg-center col-lg-4 my-3 my-lg-5">
            <div className="d-flex  gap-3">
              <div>
                <img loading="lazy" src={locationIcon} />
              </div>

              <div className="">
                <h3 className="para">Office address</h3>
                <h4>
                  <a
                    href="https://www.google.com/maps/place/13100+Wortham+Center+Dr+3rd+floor,+Suite+358,+Houston,+TX+77065,+USA/@29.9205603,-95.6180849,17z/data=!3m1!4b1!4m5!3m4!1s0x8640d11267ecbbaf:0x1a9b55bb1fb96abd!8m2!3d29.9205603!4d-95.61551?entry=ttu&g_ep=EgoyMDI1MDMxNi4wIKXMDSoASAFQAw%3D%3D"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    13100 Wortham Center Dr 3rd floor,
                    <span className="d-lg-block">
                      {" "}
                      Suite 358 Houston, TX 77065, USA
                    </span>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
