import React from "react";

function ExploreFeature({ data }) {
  return (
    <>
      <div className="container py-5 d-flex flex-column align-items-center">
        <div className="col-lg-8 d-flex flex-column gap-3 my-md-5">
          <h2>{data?.roleHeading}</h2>
          <ul>
            {data?.rolePoints.map((role, index) => {
              const splitRole = role.split(" – ");
              const firstWords = splitRole[0].split(" ").slice(0, 2).join(" "); // Get first two words
              const restOfRole = splitRole[1]; // The part after the dash

              return (
                <li key={index} className="py-2">
                  <strong>{firstWords}</strong> – {restOfRole}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div
        style={{
          backgroundImage:
            "linear-gradient(to bottom, #FFF8F2,#FFF8F2, #ffffff",
        }}
        className="pb-5"
      >
        <div className="container d-flex justify-content-center align-items-center">
          <img src={data?.img_src} alt="" className="img-fluid" />
        </div>
      </div>

      <div className="container py-5 d-flex flex-column align-items-center bg-white">
        <div className="col-lg-12 d-flex flex-column mt-5">
          <h1 className="text-center">{data?.approachHeading}</h1>
          <div className="d-flex flex-wrap justify-content-center mt-md-2">
            {data?.technologies.map((data, index) => (
              <div className="col-6 col-md-4 col-xl-3 mt-4 d-flex align-items-center justify-content-center">
                <div className="w-75 d-flex align-items-center justify-content-center technology-section">
                  <img src={data?.img_src} alt="" />
                </div>
                {/* <p className="mt-2">{data?.description}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ExploreFeature;
