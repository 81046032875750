import React from "react";
import "./ServicesCard.css";

function ServicesCard({ serviceData, servicedataheadings, isAI = false }) {
  return (
    <div className="services pb-5 py-5">
      <div className="custom-container">
        {isAI ? (
          <div className="py-xl-5">
            <div className="d-flex flex-column align-items-center">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-offset="30%"
                className="d-flex flex-column align-items-center"
              >
                <h1 className="characterbackground">
                  <div className="circle-spacing">R</div>
                  <span>einvent with</span>
                </h1>
                <h1>data and AI</h1>
              </div>
              <p className="text-center col-lg-7 pt-4">
                Sixlogs empowers businesses with AI-driven insights and data
                solutions. Our experts optimize processes, enhance
                decision-making, and drive digital transformation. From seamless
                integration to continuous innovation, we ensure your business
                stays ahead with cutting-edge technology and intelligent
                automation.
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          {serviceData?.map((item, index) => (
            <div key={index} className="col-md-6 col-xl-4 my-4">
              <div className="box h-100">
                <h2 className="font-face-Syne-Bold">{item.title}</h2>
                <p
                  className={`${
                    index == serviceData.length - 1 ? "" : "pe-md-5"
                  }`}
                >
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
