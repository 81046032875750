import React from "react";
import "./DataandAI.css";
import dataAndAi from "../assets/images/data-and-ai.png";
function DataandAI() {
  return (
    <div className="dataandAI py-xxl-5 font-face-DMSans-Regular">
      <div className="custom-container">
        <div className="row py-5 my-5">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <img loading="lazy" src={dataAndAi} alt="img-fluid" className="vector" />
          </div>
          <div className="col-lg-6 py-4">
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-offset="30%"
              className="characterbackground mt-5"
            >
              <div className="circle-spacing">D</div>
              <span>ata and AI now</span>
            </h1>
            <p className="pt-3 col-xl-11">
              Data and artificial intelligence (AI) fusion reshapes industries
              and drives unprecedented innovation in this digital age. As
              businesses strive to remain competitive, harnessing the power of
              AI becomes paramount. Recent studies reveal that over{" "}
              <b>90% of business leaders</b> believe AI is crucial for their
              organization's future success. Furthermore,{" "}
              <b>70% of companies</b> plan to significantly increase their AI
              investments within the next year.
            </p>
            <p className="pt-3 col-xl-11">
              AI technologies, from machine learning to natural language
              processing, enable smarter decision-making and operational
              efficiency. However, challenges remain;
              <b> over 50% of organizations</b> cite data quality and readiness
              as significant hurdles. Addressing these challenges is essential
              for fully realizing AI's potential.
            </p>
            <p className="pt-3 col-xl-11">
              At Sixlogs Technologies, we specialize in leveraging AI to
              transform data into actionable insights. Our tailored solutions
              help businesses navigate the complexities of AI adoption, ensuring
              they stay ahead in an increasingly data-driven world. Embrace the
              future with Sixlogs and unlock the transformative power of AI.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataandAI;
