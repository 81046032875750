import React from "react";
import "./AboutUsPage.css";
import ContactForm from "../components/ContactForm";
import MarqueeCard from "../components/MarqueeCard";
import NearShore from "../components/NearShore";
import WhySixlogs from "../components/WhySixlogs";
import SetsOurTalent from "../components/SetsOurTalent";
import FounderIntro from "../components/FounderIntro";
import BannerCenterHeading from "../components/BannerCenterHeading";
import logoOverlay from "../assets/images/logoOverlay.svg";

function AboutUsPage() {
  const tittle = "Find out more ";
  const tittle2 = "About us";
  const paragraph =
    "At Sixlogs, we empower businesses with tailored, innovative solutions that enhance efficiency and drive growth. From Salesforce consulting and digital marketing to business and IT solutions, AWS services, and mobile and web app development, we cover all your digital needs. Since our inception, we've focused on helping entrepreneurs create impactful software solutions. We pride ourselves on delivering digital products that meet our client's needs and contribute to the greater good. Our dedicated team provides exceptional service and fosters a collaborative environment where ideas thrive. Join us on our journey to make a difference through technology.";
  return (
    <div className="aboutus font-face-DMSans-Regular">
      <img loading="lazy" src={logoOverlay} alt="" className="overlay-img img-fluid" />
      <div className="bg-linear">
        <div
          className="custom-container"
          style={{ position: "relative", zIndex: "30" }}
        >
          <BannerCenterHeading
            description={
              "At Sixlogs, we empower businesses with tailored, innovative solutions that enhance efficiency and drive growth."
            }
          />
        </div>
      </div>
      <div
        className="custom-container"
        style={{ position: "relative", zIndex: "30" }}
      >
        <FounderIntro />
        <NearShore />
        <MarqueeCard />
        <WhySixlogs />
        <SetsOurTalent />
      </div>
      <ContactForm />
    </div>
  );
}

export default AboutUsPage;
