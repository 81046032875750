import React from "react";
import ContactForm from "../components/ContactForm";
import ServicesCard from "../components/ServicesCard";
import AiImg from "../assets/images/AiImg.svg";
import DataandAI from "../components/DataandAI";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import AIBanner from "../components/AIBanner";
import AIExperties from "../components/AIExperties";

function ArtificialIntelligencePage() {
  const tittle = "Enhance Your Business with Artificial Intelligence";
  const description =
    "Unlock the full potential of your business with Sixlogs Technologies' AI solutions. Our expertise in artificial intelligence allows you to automate processes, gain insightful analytics, and deliver superior customer experiences. From machine learning models to natural language processing, our tailored AI services address your unique business challenges. Dive into the future with intelligent systems that not only understand but predict and optimize your business operations. Let us guide you through the AI landscape, ensuring seamless integration and transformative results.";
  const description2 = "";
  const paragraph = "";
  const imgsrc = AiImg;
  const servicedataheadings = [
    {
      tittle: "Reinvent with data and AI",
      tittle2: "",
      discriptions: "",
      para: " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.",
    },
  ];
  const serviceData = [
    {
      title: "Data Services",
      description:
        "At Sixlogs Technologies, we help you build a modern data foundation ready for consumption and analysis. By optimizing and updating your data operations, we enable you to achieve unparalleled speed, scalability, and innovation.",
    },
    {
      title: "Generative AI",
      description:
        "Harness the power of generative AI to transform your business operations and unlock new levels of creativity and productivity. At Sixlogs Technologies, we implement AI solutions that leverage advanced machine learning algorithms to generate new ideas.",
    },
    {
      title: "Responsible AI",
      description:
        "We help you take intentional steps to operationalize responsible AI, creating value while protecting your company from risks. Our approach ensures that your AI initiatives are not only effective but also trustworthy and compliant with industry standards.",
    },
    {
      title: "AI Strategy and Value",
      description:
        "To fully benefit from AI, businesses must adopt it as an integral part of their continuous innovation strategy. At Sixlogs Technologies, we guide you in developing a comprehensive AI strategy that maximizes return on investment. ",
    },
    {
      title: "AI Talent",
      description:
        "The successful implementation of AI depends on having the right talent. At Sixlogs Technologies, we place people at the heart of innovation. We assist organizations in preparing their workforce for the AI revolution by providing necessary skills.",
    },
    {
      title: "A Clear Path to Value",
      description:
        " At Sixlogs Technologies, we provide a roadmap that guides your AI journey from concept to implementation. Our clear path to value ensures that your AI projects are aligned with your business objectives, delivering measurable results.",
    },
  ];
  return (
    <div>
      <AIBanner />
      <ServicesCard serviceData={serviceData} isAI={true} />
      <DataandAI />
      <AIExperties />
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default ArtificialIntelligencePage;
