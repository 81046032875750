import React from "react";
import InfoCards from "../components/InfoCards";
import FeedBack from "../components/FeedBack";
import ContactForm from "../components/ContactForm";
import "./ClientsPage.css";
import MarqueeCard from "../components/MarqueeCard";

function ClientsPage() {
  const Heading = "Success Stories";
  const SubHeading = "Clients";
  return (
    <div className="Clients">
      <InfoCards Heading={Heading} SubHeading={SubHeading} />
      <FeedBack />
      <ContactForm />
    </div>
  );
}

export default ClientsPage;
