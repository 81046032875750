import React, { useEffect, useState } from "react";
import "./FaqSection.css";
import CrossIcon from "../assets/images/CrossIcon.svg";
import PlusIcon from "../assets/images/PlusIcon.svg";

function FaqSection(props) {
  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  //     {
  //         heading:
  //             "Do I really need Amazon Marketing Service Professionals for my business?",

  //         description:
  //             "An Amazon marketing service specialist will assist you to promote your product, effectively manage your online store, and bring more visitors to your product website, no matter whether you are a small seller or an established business. Consequently, you will be able to advertise your products effectively and adhere to all Amazon guidelines.",
  //     },
  //     {
  //         heading: "How do I know I have an effective Amazon strategy?",

  //         description:
  //             "You have an effective Amazon strategy in mind when your product is always ahead in the search list and thus continues to be more visible to consumers. Furthermore, your ad campaigns should be highly effective in terms of ROI, and, there should be a constant increase in the customer reviews and ratings. Periodically reviewing important activity measurements and adjusting the technique based on the numbers collected is essential for strategy optimization.",
  //     },
  //     {
  //         heading:
  //             "I have a successful Amazon store. Do I need to hire Amazon Experts for my online business?",

  //         description:
  //             "Hiring Amazon marketing experts can be advantageous for your company even when you already have a successful Amazon store. Advertisers can enhance your listings, advertise properly when it comes to campaigns, and offer you information on recent market and ad strategies. This is because they have specialized knowledge and will be able to attract more people into your store thus enhance sales revenue and growth.",
  //     },
  //     {
  //         heading:
  //             "How does FBAXperts ensure the growth of my E-commerce business?",

  //         description:
  //             "We focus on data-driven strategies and continuous optimization to ensure the growth of your E-commerce business. Our expert team monitors performance, adjusts tactics as needed, and provides ongoing support to help you achieve sustained success across all platforms.",
  //     },
  //     {
  //         heading:
  //             "Can FBAXperts handle multiple E-commerce platforms simultaneously?",

  //         description: `Yes, FBAExperts can manage and optimize your presence across multiple E-commerce platforms, including Amazon, Shopify, Walmart, and TikTok. We provide integrated solutions to ensure consistent performance and growth across all your sales channels.`,
  //     },
  // ];
  return (
    <div className="result-driven global-background">
      <div className="custom-container py-lg-5">
        {props?.isHeading && (
          <div className="text-center d-flex flex-column justify-content-center align-items-center">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-offset="30%"
              className="text-center"
            >
              <div className="d-flex flex-column flex-sm-row gap-sm-2 align-items-center">
                <h1 className="characterbackground h1">
                  <div>W</div>
                  <span>hat you</span>
                </h1>
                <h1 className="h1">
                  <span className="d-lg-block">wanted to know</span>
                </h1>
              </div>
              <h1 className="h1">
                <span className="d-lg-block">about working with us - FAQ</span>
              </h1>
            </div>

            <h3 className="mt-4">
              All your frequently asked questions about working
              <span className="d-lg-block">at Sixlogs answered</span>
            </h3>
          </div>
        )}

        <div className="row d-flex justify-content-center mt-3">
          <div className="col-lg-9 col-xl-7 px-lg-5 d-flex flex-column justify-content-center">
            <div className="FAQ-main">
              <div className="">
                {props?.FAQDATA?.map((item, i) => (
                  <div className="item" key={i}>
                    <div className="title" onClick={() => toggle(i)}>
                      <div className="title-inner ">
                        {selected === i ? (
                          <h3 className="py-2 py-lg-3 faq-h3">{item.title}</h3>
                        ) : (
                          <h3 className="py-2 py-lg-3 faq-h3">{item.title}</h3>
                        )}
                        {selected === i ? (
                          <img loading="lazy" src={CrossIcon} alt="icon" />
                        ) : (
                          <img loading="lazy" src={PlusIcon} alt="icon1" />
                        )}
                      </div>

                      <div
                        className={selected == i ? "content show" : "content"}
                      >
                        <div>
                          <div className="row">
                            <div className="w-100">
                              <p className="text-theme-light-white">
                                {item.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
