import React from "react";
import HeroBanner from "../components/HeroBanner";
import augmentation from "../assets/images/staff-banner.svg";
import ServicesCard from "../components/ServicesCard";
import BuildWithTeams from "../components/BuildWithTeams";
import ContactForm from "../components/ContactForm";
import ClientsExperience from "../components/ClientsExperience";
import AugmentationServices from "../components/AugmentationServices";
import Platform1 from "../assets/images/inner-pages-images/it-staff-1.png";
import Platform2 from "../assets/images/inner-pages-images/it-staff-2.png";
import SolutionOverviewImg from "../assets/images/inner-pages-images/it-staff-3.png";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import SolutionOverview from "../components/SolutionOverview";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";

function ITStaffAugmentationPage() {
  const bannerData = {
    pageName: "IT-Staff Augmentation",
    description: [
      "Having the right-sized team is critical to the success of any project, especially as businesses grow and evolve. Staff augmentation services provide a flexible solution to meet your expanding needs by allowing you to bring in top-tier professionals without the long-term commitment. This model ensures you have access to the industry's best talent, tailored to your project's specific requirements.",

      "Staff augmentation offers the speed, expertise, and flexibility necessary to scale your team quickly and efficiently. Whether you need additional skills for a new project or to expedite development timelines, our staff augmentation services can provide the perfect fit. By integrating seamlessly with your existing team, these professionals bring the specialized knowledge and experience required to drive your projects to success.",
    ],
    imgsrc: augmentation,
  };

  const serviceData = [
    {
      title: "Dedicated Teams",
      description:
        "Sixlogs Technologies offers dedicated teams designed to align with your project requirements. Our staff augmentation services ensure exclusive engineering and specialist support, enabling seamless collaboration with your in-house team.",
    },
    {
      title: "Top 1% IT Talent",
      description:
        "Gain access to the top 1% of IT talent with Sixlogs Technologies. Our stringent selection process ensures that only the most skilled professionals join your team, bringing innovation, expertise, and deep technical knowledge.",
    },
    {
      title: "24/7 Recruitment Process",
      description:
        "Our around-the-clock recruitment process ensures rapid staffing solutions. Sixlogs Technologies quickly adapts to your evolving project needs, providing top-tier professionals whenever you need them to maintain momentum and meet deadlines.",
    },
    {
      title: "Maintain Oversight & Control",
      description:
        "Retain full control over your projects while leveraging our expertise. Sixlogs Technologies’ professionals seamlessly integrate into your workflows, ensuring transparency, accountability, and alignment with your business objectives.",
    },
    {
      title: "Save Time",
      description:
        "Accelerate your project timelines by eliminating lengthy hiring processes. With Sixlogs Technologies’ ready-to-deploy experts, you can fill skill gaps instantly, allowing you to focus on core business goals and execution.",
    },
    {
      title: "Expertise",
      description:
        "Sixlogs Technologies provides specialized expertise across multiple domains. Whether you need software engineers, data scientists, project managers, or cybersecurity specialists, our professionals bring in-depth experience to drive success in your projects.",
    },
  ];

  // const solutions = [
  //   {
  //     title: "IT Staff Augmentation",
  //     items: [
  //       {
  //         description:
  //           "IT staff augmentation offers organizations the flexibility to scale their teams with highly skilled professionals as needed. This model provides access to hourly or part-time software engineers, developers, and other IT specialists who integrate seamlessly with your in-house team. By leveraging staff augmentation, you can fill critical skill gaps quickly, avoid the lengthy recruitment process, and maintain control over your projects.With staff augmentation, you benefit from a cost-effective and efficient way to enhance your team’s capabilities without the commitment of permanent hires. This approach allows you to tap into a vast pool of talent with diverse expertise, ensuring that you have the right skills available when you need them. Whether you are dealing with a temporary surge in workload, require specialized skills for a particular project, or want to expedite development timelines, staff augmentation provides the agility to respond to changing demands swiftly.This model is ideal for businesses needing to meet fluctuating project demands while ensuring high-quality outcomes without long-term commitments. By integrating augmented staff into your existing processes, you retain full oversight and control, ensuring that your project goals and company standards are consistently met.",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Delivery Teams",
  //     items: [
  //       {
  //         title: "Discovery Phase",
  //         description:
  //           "We work closely with you to validate your ideas, define clear objectives, and understand market demands. This phase ensures that your project starts on a solid foundation, aligning your vision with market needs.",
  //       },
  //       {
  //         title: "MVP Development",
  //         description:
  //           "Our team specializes in building Minimum Viable Products (MVPs) that include essential features to test your business concept quickly in the market. This approach allows you to gather user feedback and iterate rapidly.",
  //       },
  //       {
  //         title: "Product Road mapping",
  //         description:
  //           "We guide your product's development from launch to scale, creating a clear, strategic roadmap tailored to your business goals. Our roadmaps help you prioritize features, manage resources efficiently, and stay on track.",
  //       },
  //       {
  //         title: "Consulting",
  //         description:
  //           "With our years of expertise, we help refine your strategy, optimize resource allocation, and ensure your startup gains a competitive edge. Our consulting services are designed to support your growth and success in a competitive market.",
  //       },
  //     ],
  //   },
  // ];

  const platformData = {
    pageName: "it-staff",
    imgsrc: Platform1,
    title: "Seamless Integration – Maximum Impact",
    description:
      "At Sixlogs Technologies, we recognize that each project requires unique skills. Our staff augmentation services connect you with top industry professionals, ensuring efficiency and effectiveness. Whether you need software developers, project managers, or technical specialists, we provide the right talent to meet your needs.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "Seamless Integration:",
        ulDescription:
          "Augmented staff work as an extension of your team, attending regular meetings and aligning with your company culture.",
      },
      {
        ulHeading: "Enhanced Capabilities:",
        ulDescription:
          "Expand your team’s expertise to tackle complex challenges and accelerate project delivery.",
      },
      {
        ulHeading: "Competitive Advantage:",
        ulDescription:
          "Quickly adapt to market demands with flexible and scalable staffing solutions.",
      },
    ],
  };

  const lightningData = {
    pageName: "it-staff",
    imgsrc: Platform2,
    title: "Flexible Solutions – Tailored to Your Needs",
    description:
      "At Sixlogs Technologies, we provide two strategic models to help businesses scale efficiently: IT Staff Augmentation and Delivery Teams. Each approach offers unique benefits to match your project requirements.",
    subheading: "Our approach includes:",

    unOrderData: [
      {
        ulHeading: "IT Staff Augmentation:",
        ulDescription:
          "Scale your team with skilled professionals on demand. This model provides access to hourly or part-time IT specialists who seamlessly integrate with your in-house team, filling critical skill gaps while maintaining full project control.",
      },
      {
        ulHeading: "Delivery Teams:",
        ulDescription:
          "A fully autonomous team takes complete responsibility for your project. Composed of project managers, engineers, and QA testers, this model ensures end-to-end delivery of high-quality technology solutions.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "it-staff",
    title: "Clarifying the Facts – Empowering Your Decisions",
    imgsrc: SolutionOverviewImg,
    description: [
      "IT staff augmentation is often misinterpreted, with many assuming its primary purpose is cost reduction. While it does offer cost-efficiency, its real value lies in providing businesses with the flexibility to scale teams quickly and access specialized expertise without long-term commitments. This model enables organizations to adapt swiftly to project demands, ensuring seamless integration with in-house teams.",
      "A common misconception is that staff augmentation is the same as outsourcing. In reality, augmented staff work alongside internal teams, maintaining direct project control and alignment with business goals. This approach fosters collaboration while enhancing operational efficiency.",
      "Concerns about difficulty and risk in IT staffing often stem from outdated perceptions. With proper planning and thorough vetting, staff augmentation can be a strategic asset, allowing businesses to customize solutions without excessive risks. Moreover, having access to top-tier developers and specialists ensures streamlined software development, enhancing innovation and project success.",
    ],
  };
  return (
    <div>
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default ITStaffAugmentationPage;
