import React, { useEffect, useRef } from "react";
import { MdOutlineArrowOutward } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import HeroBanner from "../assets/images/HeroBanner.svg";
import bgvideo from "../assets/videos/Bannervideo.webm";
import ScrollDown from "../assets/images/ScrollDown.png";
import ScrollDownwhite from "../assets/images/ScrollDown.svg";
import "./Banner.css";
import { useNavigate } from "react-router-dom";

const Banner = ({ setHover }) => {
  const nextSectionRef = useRef(null);
  const bannerVideoRef = useRef(null);

  useEffect(() => {
    const video = bannerVideoRef.current;

    if (video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.error("Autoplay error:", error);
          });
      }
    }
  }, []);

  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      const offset = 80; // Adjust this value based on your header height or padding

      const sectionTop =
        nextSectionRef.current.getBoundingClientRect().top + window.pageYOffset;
      const targetPosition = sectionTop - offset;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };
  const navigate = useNavigate();
  const navigateToPage = (path, newTab = false) => {
    if (newTab) {
      window.open(path, "_blank");
    } else {
      navigate(path);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    }
  };

  return (
    <div className="banner">
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="overlay"
      ></div>
      <video
        className="banner-bg-video"
        ref={bannerVideoRef}
        muted
        playsInline
        loop
        controls={false}
        style={{ width: "100%" }}
      >
        <source src={bgvideo} />
      </video>
      <img
        loading="lazy"
        className="banner-bg-img"
        src={HeroBanner}
        alt="Hero Banner"
      />

      <div className="banner2-content d-flex">
        <img
          loading="lazy"
          onClick={scrollToNextSection}
          src={ScrollDown}
          alt="ScrollDown"
          role="button"
          className="scroll-down-animation scrollaction"
          style={{ position: "absolute", left: "10%", bottom: "10%" }}
        />
        <img
          loading="lazy"
          onClick={scrollToNextSection}
          src={ScrollDownwhite}
          alt="ScrollDown"
          className="scroll-down-animation scrollactionwhite"
          role="button"
          style={{ position: "absolute", left: "10%", bottom: "10%" }}
        />
        <div className="banner-text">
          <h5 className="d-flex align-items-center gap-2 font-face-Syne-Bold mt-4 mt-md-0">
            <div data-aos="fade-up" className="line"></div>Transform Your
            Business With
          </h5>
          <h1 data-aos="fade-up" className="characterbackground heading-blend">
            <div className="circle-spacing">D</div>
            <span>igital</span>
          </h1>
          <h1 data-aos="fade-up" className="heading-blend">Evolution</h1>
          {/* <h1 className="text-start mt-4 mt-md-0">
            <span className="orange-circle">D</span>igital
            <br />
          </h1> */}
          <h5 className="font-face-Syne-Bold mt-4 mt-md-0 from-left">
            Elevate your processes with Sixlogs Technologies
          </h5>
          <div className="buttons d-flex flex-row gap-3">
            <button
              onClick={() => {
                navigateToPage("/contact-us");
              }}
            >
              <span>Let’s talk</span> <MdOutlineArrowOutward className="icon" />
            </button>
            <button
              onClick={() => {
                navigateToPage(
                  "https://portfolio.sixlogs.com/",
                  true
                );
              }}
            >
              <span>Our Work</span> <MdOutlineArrowOutward className="icon" />
            </button>
          </div>
          <div className="banner-client d-flex flex-wrap flex-column flex-sm-row gap-4 align-items-sm-center mt-5">
            <div className="d-flex flex-wrap gap-4 align-items-center">
              <div className="d-flex align-items-center gap-1">
                <h2 className="font-face-Syne-Bold">1.2</h2>
                <h3 className="font-face-Syne-Bold mt-2">k +</h3>
              </div>
              <h5 className="font-face-DMSans-Regular mt-1">
                Worldwide clients
              </h5>
            </div>
            <div className="d-flex flex-wrap gap-4 align-items-center">
              {/* <a
                href="https://www.facebook.com/sixlogstechnologies"
                target="_blank"
              >
                <FaFacebookF size={24} />
              </a>
              <a href="https://twitter.com/sixlogs">
                <FaTwitter size={24} />
              </a> */}
              <a
                href="https://www.linkedin.com/company/sixlogs"
                target="_blank"
              >
                <FaLinkedinIn size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div ref={nextSectionRef} className="next-section"></div>
    </div>
  );
};

export default Banner;
