import React from "react";
import "./InfoCards.css";
import InfoImage1 from "../assets/images/InfoImage1.svg";
import InfoImage2 from "../assets/images/InfoImage2.svg";
import InfoImage3 from "../assets/images/InfoImage3.svg";
import arrowright from "../assets/images/arrowright.svg";
import { useNavigate } from "react-router-dom";

const InfoCards = ({ Heading, SubHeading }) => {
  const data = [
    {
      img_src: InfoImage1,
      href: "/staff-augmentation",
      subHeading: "Top 1% Tech Talent",
    },
    {
      img_src: InfoImage2,
      href: "/ai",
      subHeading: "AI-enhanced Talent Matching",
    },
    {
      img_src: InfoImage3,
      href: "/staff-augmentation",
      subHeading: "Delivery Team",
    },
  ];
  const navigate = useNavigate();
  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <div className="InfoCards">
      <div className="custom-container">
        <div className="d-flex flex-column align-items-center">
          <h5 className="font-face-Syne-Bold">{SubHeading}</h5>
          {Heading === "way" ? (
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="d-flex flex-column flex-md-row align-items-center gap-md-4 mt-4 mt-md-0"
            >
              <h1>The</h1>
              <h1 className="characterbackground ">
                <div className="circle-spacing">S</div>
                <span>ixlogs Way</span>
              </h1>
            </div>
          ) : (
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              className="characterbackground mt-4 mt-md-0"
            >
              <span className="me-2 me-md-3">Success</span>
              <div className="circle-spacing">S</div>
              <span>tories</span>
            </h1>
          )}
        </div>
        <div className="d-flex flex-column flex-md-row gap-4 mt-3 mt-lg-5 mainbox">
          {data?.map((item, index) => (
            <div
              onClick={() => navigateToPage(item?.href)}
              key={index}
              className="griditems"
            >
              <img loading="lazy" src={item?.img_src} alt="" className="img-fluid" />
              <div className="d-flex justify-content-between align-items-center">
                <p className="font-face-Syne-Bold">{item?.subHeading}</p>
                <div>
                  <img loading="lazy" src={arrowright} alt="" height="30" width="30" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfoCards;
