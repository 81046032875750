import React from "react";
import "./Footer.css";
import logo from "../assets/images/NavbarLogoWhite.svg";
import faceBookIcon from "../assets/images/faceBookIcon.svg";
import twitterIcon from "../assets/images/twitterIcon .svg";
import LinkinIcon from "../assets/images/LinkinIcon.svg";
import { useNavigate } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  // const navigateToPage = (pageLink) => {
  //   Navigate(pageLink);
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 0);
  // };
  const navigate = useNavigate();

  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  return (
    <>
      <div className="Footermain-div">
        <div className="custom-container ">
          <div className="d-flex flex-wrap column">
            <div className="column1">
              <div
                style={{ width: "fit-content" }}
                role="button"
                onClick={() => {
                  navigateToPage("/");
                }}
              >
                <img loading="lazy" src={logo} />
              </div>
              <p className="mt-4 pt-1 p1">
                Our firm is designed to operate as one single partnership united
                by a strong set of values, including a deep commitment to
                diversity. We take a consistent approach to recruiting and
                skills development so that we can quickly deliver the right
                team, with the right experience and expertise, to every client,
                every time.
              </p>
              <div className="d-flex gap-4 align-items-center mt-md-4">
                {/* <a
                  href="https://www.facebook.com/sixlogstechnologies"
                  target="-blank"
                >
                  <img loading="lazy" src={faceBookIcon} />
                </a>
                <a href="https://twitter.com/sixlogs">
                  <img loading="lazy" src={twitterIcon} />
                </a> */}
                {/* <a href="https://www.instagram.com/sixlogstechnologies/">
                  <FaInstagram />
                </a> */}
                <a
                  href="https://www.linkedin.com/company/sixlogs"
                  target="-blank"
                >
                  <img loading="lazy" src={LinkinIcon} />
                </a>
              </div>
            </div>

            <div className="column2">
              <h2>Contact</h2>

              <div className="d-flex flex-column gap-2">
                <p className="p1">ADDRESS:</p>
                <a
                  href="https://www.google.com/maps/place/13100+Wortham+Center+Dr+3rd+floor,+Suite+358,+Houston,+TX+77065,+USA/@29.9205603,-95.6180849,17z/data=!3m1!4b1!4m5!3m4!1s0x8640d11267ecbbaf:0x1a9b55bb1fb96abd!8m2!3d29.9205603!4d-95.61551?entry=ttu&g_ep=EgoyMDI1MDMxNi4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                >
                  13100 Wortham Center Dr 3rd floor, Suite 358 Houston, TX
                  77065, USA
                </a>
              </div>
              <div className="">
                <p className="p1 mt-3">EMAIL:</p>
                <a href="mailto:info@sixlogs.com">info@sixlogs.com</a>
              </div>
              {/* <div className="">
                <p className="p1 mt-3">PHONE:</p>
                <a href="tel:+1-713-213-2908">+1-713-213-2908</a>
              </div> */}
            </div>

            <div className="column2">
              <h2>Company</h2>

              <div className="d-flex flex-column gap-2">
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/about-us");
                  }}
                >
                  About Us
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/clients");
                  }}
                >
                  Clients
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/careers");
                  }}
                >
                  Productivity
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/contact-us");
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>

            <div className="column2">
              <h2>Services</h2>

              <div className="d-flex flex-column gap-2">
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/salesforce-services");
                  }}
                >
                  Salesforce Consulting
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/business-solution");
                  }}
                >
                  Business Solution
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/custom-development");
                  }}
                >
                  Custom Development
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/staff-augmentation");
                  }}
                >
                  Staff Augmentation
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/digital-marketing");
                  }}
                >
                  Digital Marketing
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/hubspot");
                  }}
                >
                  HubSpot
                </p>
                <p
                  className="p1"
                  role="button"
                  onClick={() => {
                    navigateToPage("/ai");
                  }}
                >
                  AI & LLM
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center py-3 align-items-center Footermain-last-div">
        <p className="text-white pt-2 text-center">
          {" "}
          © 2025 Sixlogs Technologies. All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
