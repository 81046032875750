import React from "react";
import explore1 from "../assets/images/explore-1.png";

function ExploreAbout({ data }) {
  return (
    <div className="container d-flex flex-column align-items-center ">
      <div className="col-md-8 py-lg-5 px-0">
        <div className="py-3">
          <h2>{data?.heading1}</h2>
          <p>{data?.description1}</p>
        </div>
        <div className="py-3">
          <h2>{data?.heading2}</h2>
          <p>{data?.description2}</p>
        </div>
      </div>
      <img src={data?.img_src} alt="" className="w-100" />
    </div>
  );
}

export default ExploreAbout;
