import React from "react";

function ExploreBanner({ data }) {
  return (
    <>
      <div
        style={{
          backgroundImage: "linear-gradient(to bottom, #FFF8F2, #ffffff",
        }}
        className="py-4 py-lg-5 w-100"
      >
        <div className="container p-lg-5">
          <div className="row">
            <div className="col-md-6">
              <h1>{data?.heading}</h1>
            </div>
            <div className="col-md-6">
              <p>{data?.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#FFE9D9",
        }}
        className="w-100"
      >
        <div className="container px-md-5">
          <img src={data?.img_src} alt="" className="w-100" />
          <div className="row p-lg-5 pt-5">
            <div className="col-lg-6">
              <h5>{data?.aboutProject?.heading}</h5>
              <p className="mt-4">{data?.aboutProject?.description}</p>
            </div>
            <div className="col-lg-3">
              <h5>{data?.services?.heading}</h5>
              <div className="d-flex flex-column gap-3 mt-4">
                {data?.services?.servicePoint.map((x, index) => (
                  <li
                    key={index}
                    style={{ listStyle: "none", lineHeight: "20px" }}
                  >
                    {x}
                  </li>
                ))}
              </div>
            </div>
            <div className="col-lg-3">
              <h5>{data?.scope?.heading}</h5>
              <div className="d-flex flex-column gap-3 mt-4">
                {data?.scope?.scopePoint.map((x, index) => (
                  <li
                    key={index}
                    className=""
                    style={{ listStyle: "none", lineHeight: "20px" }}
                  >
                    {x}
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExploreBanner;
