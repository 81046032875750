import React, { useEffect, useState } from "react";
import "./LightningDevelopment.css";
import bgZigZag from "../assets/images/bgZigZag.svg";
import Platform2 from "../assets/images/platfprm2.svg";

function LightningDevelopment({ data }) {
  const [heading, setHeading] = useState("");
  useEffect(() => {
    setHeading(
      data?.pageName == "salesforce" ? (
        <>
          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-lg-3">
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-offset="30%"
              className="characterbackground"
            >
              <div className="circle-spacing">L</div>
              <span>ightning</span>
            </h1>
            <h1
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
              data-aos-offset="30%"
              className="characterbackground"
            >
              Development
            </h1>
          </div>
        </>
      ) : data.pageName == "business-solution" ? (
        <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-lg-3">
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">P</div>
            <span>roduct</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Roadmapping
          </h1>
        </div>
      ) : data.pageName == "custom-app-development" ? (
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-offset="30%"
        >
          <h1 className="characterbackground">
            <div className="circle-spacing">A</div>
            <span>gile & Scalable</span>
          </h1>
          <h1 className="characterbackground">Solutions</h1>
        </div>
      ) : data.pageName == "it-staff" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>
              taff <span className="show-upto">Augmentation</span>{" "}
            </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Augmentation
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className=""
          >
            vs. Delivery <span className=" pt-2">Teams</span>
          </h1>
        </>
      ) : data.pageName == "digital-marketing" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">D</div>
            <span>
              ata-Driven <span className="show-upto">Growth</span>
            </span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground hide-upto"
          >
            Growth
          </h1>
        </>
      ) : data.pageName == "it-solution" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">S</div>
            <span>mart Growth</span>
          </h1>
          {/* <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            Growth
          </h1> */}
        </>
      ) : data.pageName == "hub-spot" ? (
        <>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            <div className="circle-spacing">H</div>
            <span>ubSpot Consulting</span>
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-offset="30%"
            className="characterbackground"
          >
            vs. Managed Services
          </h1>
        </>
      ) : (
        <></>
      )
    );
  }, []);

  return (
    <div className="platform">
      <div className="lightning-section py-5">
        <div className="custom-container pt-xxl-5">
          <div className="row d-flex flex-column flex-lg-row justify-content-center align-items-center py-5">
            {/* <div className="bg-Img ">
              <img loading="lazy" src={bgZigZag} alt="" className="img-fluid" />
            </div> */}
            <div className="col-lg-5 py-xxl-5">
              <div className="w-100 d-flex align-items-center justify-content-center">
                <img loading="lazy" src={data.imgsrc} className="img-fluid" />
              </div>
            </div>
            <div className="content-section col-lg-6 mt-5 mt-lg-0 py-xxl-5">
              <div>
                {heading}

                {/* <h1>Lightning Development</h1> */}
                <h4 className="my-4">{data.title}</h4>
                <p>{data.description}</p>
                <div className="ul-section">
                  <p>{data.subheading}</p>
                  <ul className="col-lg-11 d-flex flex-column gap-2">
                    {data.unOrderData.map((unorder, index) => (
                      <li key={index} className="pt-1">
                        <b>{unorder.ulHeading}</b> {unorder.ulDescription}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LightningDevelopment;
