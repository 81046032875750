import React from "react";
import marquee1 from "../assets/images/marquee-brand-logos/marquee-1.svg";
import marquee2 from "../assets/images/marquee-brand-logos/marquee-2.svg";
import marquee3 from "../assets/images/marquee-brand-logos/marquee-3.svg";
import marquee4 from "../assets/images/marquee-brand-logos/marquee-4.svg";
import marquee5 from "../assets/images/marquee-brand-logos/marquee-5.svg";
import marquee6 from "../assets/images/marquee-brand-logos/marquee-6.svg";
import marquee7 from "../assets/images/marquee-brand-logos/marquee-7.svg";
import marquee8 from "../assets/images/marquee-brand-logos/marquee-8.svg";
import marquee9 from "../assets/images/marquee-brand-logos/marquee-9.svg";
import marquee10 from "../assets/images/marquee-brand-logos/marquee-10.svg";
import marquee11 from "../assets/images/marquee-brand-logos/marquee-11.svg";
function MarqueeCard() {
  const brandLogos = [
    marquee1,
    marquee2,
    marquee3,
    marquee4,
    marquee5,
    marquee6,
    marquee7,
    marquee8,
    marquee9,
    marquee10,
    marquee11,
  ];
  return (
    <div>
      <div className="marquee-container">
        <div className="marquee">
          <div className="marquee-content">
            {brandLogos.map((logo, index) => (
              <div key={index} className="logo">
                <img loading="lazy" src={logo} alt={`Brand Logo ${index + 1}`} />
              </div>
            ))}
            {brandLogos.map((logo, index) => (
              <div key={`duplicate-${index}`} className="logo">
                <img loading="lazy" src={logo} alt={`Duplicate Logo ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarqueeCard;

// import React from "react";
// import "./MarqueeCard.css"
// function MarqueeCard() {
//   return (
//     <div className="marquee-section">
//       <div className="wrapper">
//         <div className="item item1"></div>
//         <div className="item item2"></div>
//         <div className="item item3"></div>
//         <div className="item item4"></div>
//         <div className="item item5"></div>
//         <div className="item item6"></div>
//         <div className="item item7"></div>
//         <div className="item item8"></div>
//       </div>
//     </div>
//   );
// }

// export default MarqueeCard;
