import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-profitness-1.png";
import explore2 from "../assets/images/explore/explore-profitness-2.png";
import exploreMap from "../assets/images/explore/explore-profitness-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import "./MusikarExplorePage.css";

function ProfitnessExplorePage() {
  const bannerData = {
    heading: "Optimizing Performance with Profitness",
    description:
      "Explore how Profitness is transforming fitness and wellness for athletes. With smart training tools, progress tracking, and expert coaching, athletes can enhance performance and reach new milestones. Discover how Profitness is helping users train smarter and achieve peak results.",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Profitness is a fitness and wellness platform designed to help athletes train smarter, track progress, and connect with expert coaches and top fitness professionals.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Smart Training Plans",
        "Progress Tracking",
        "Expert Coaching",
        "Community & Challenges",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Mobile Development",
        "Workout Tracking",
        "Performance Analytics",
        "Coaching Features",
        "Testing & QA",
        "App Deployment",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Profitness was created to support athletes, trainers, and fitness enthusiasts by providing advanced digital tools to enhance training and performance. The client aimed to integrate expert coaching, data tracking, and community engagement in a single, powerful platform.",
    heading2: "About the Product",
    description2:
      "Profitness is a fitness and wellness platform designed to optimize training through AI-driven workout plans, progress tracking, and expert guidance. It offers social features for community-driven motivation, personalized fitness analytics, and access to professional coaching, ensuring smarter training outcomes.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "AI-Powered Training Plans – Personalized workouts tailored to fitness goals.",
      "Nutrition Guidance – Meal plans and diet tracking based on health metrics.",
      "Progress Tracking – Monitor fitness milestones and improvement trends.",
      "Challenges & Rewards – Compete with friends and earn fitness rewards.",
      "Live Coaching – One-on-one training sessions with certified professionals.",
      "Wearable Integration – Sync data from smartwatches and fitness trackers.",
      "Goal Setting – Set achievable fitness targets with step-by-step guidance.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default ProfitnessExplorePage;
