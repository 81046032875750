import React from "react";
import "./BannerCenterHeading.css";
function BannerCenterHeading({ isAbout = true, description }) {
  return (
    <div className="banner-center-heading d-flex flex-column align-items-center justify-content-center pt-3 pt-xxl-5 pb-3">
      {isAbout ? (
        <h1
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-offset="30%"
          className="characterbackground mt-5"
        >
          <div className="circle-spacing">A</div>
          <span>bout Us</span>
        </h1>
      ) : (
        <h1
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
          data-aos-offset="30%"
          className="characterbackground mt-5"
        >
          <div className="circle-spacing">C</div>
          <span>areers</span>
        </h1>
      )}
      <p className="col-md-9 col-lg-7 text-center mt-md-4">{description}</p>
    </div>
  );
}

export default BannerCenterHeading;
