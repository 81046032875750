import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-walker-1.png";
import explore2 from "../assets/images/explore/explore-walker-2.png";

import exploreMap from "../assets/images/explore/explore-walker-3.png";
import WordpressIcon from "../assets/images/explore/WordpressIcon.svg";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import SalesforceIcon from "../assets/images/explore/SalesforceIcon.svg";
import "./MusikarExplorePage.css";

function WalkerCraneExplorePage() {
  const bannerData = {
    heading: "Streamlining Operations with Walker Crane",
    description:
      " Discover how Walker Crane is transforming crane operation and logistics management for operators. With efficient scheduling, project execution, and seamless collaboration with contractors and suppliers, it enhances productivity and safety. ",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Walker Crane is a crane operation and logistics platform designed to streamline scheduling, manage lifting projects, and enhance collaboration with contractors and suppliers.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Crane Operations Management",
        "Logistics Coordination",
        "Contractor Collaboration",
        "Safety & Compliance",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Web Development",
        "Crane Scheduling",
        "Logistics Tracking",
        "Compliance Features",
        "Security Testing",
        "System Deployment",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Managing crane operations requires precision, planning, and collaboration. The client wanted an advanced logistics solution that could streamline crane scheduling, contractor coordination, and safety compliance, ensuring efficient project execution.",
    heading2: "About the Product",
    description2:
      "Walker Crane is a crane operation and logistics management platform that simplifies project planning, scheduling, and equipment tracking. It enhances contractor collaboration, ensures regulatory compliance, and optimizes efficiency, making crane operations smoother and more reliable.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Project Scheduling – Plan and manage crane operations efficiently.",
      "Equipment Tracking – Monitor crane availability and usage in real time.",
      "Logistics Coordination – Streamline transportation and setup processes.",
      "Safety Compliance Checks – Ensure adherence to industry safety regulations.",
      "Digital Documentation – Maintain crane logs, permits, and certifications.",
      "Contractor Collaboration – Connect with suppliers and project teams.",
      "Performance Analytics – Track efficiency and productivity metrics.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      // {
      //   img_src: IOSIcon,
      //   description:
      //     "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      // },
      // {
      //   img_src: AndroidIcon,
      //   description:
      //     "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      // },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      // {
      //   img_src: NodejsIcon,
      //   description:
      //     "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      // },
      // {
      //   img_src: PostmanIcon,
      //   description:
      //     "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      // },
      // {
      //   img_src: BitBucketIcon,
      //   description:
      //     "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      // },
      // {
      //   img_src: AWSIcon,
      //   description:
      //     "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      // },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      // {
      //   img_src: ExpressJsIcon,
      //   description:
      //     "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      // },
      {
        img_src: SalesforceIcon,
        description:
          "We use Salesforce as a leading CRM platform that enhances customer relationship management and business process automation.",
      },
      {
        img_src: WordpressIcon,
        description:
          "We use WordPress as a versatile content management system that enables dynamic website development with extensive customization capabilities.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default WalkerCraneExplorePage;
