import React, { useRef, useState } from "react";
import "./ContactUsPage.css";
import MailIcon from "../assets/images/MailIcon.svg";
import CallIcon from "../assets/images/CallIcon.svg";
import locationIcon from "../assets/images/locationIcon.svg";
import arrowuprighwhitet from "../assets/images/arrowuprighwhitet.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import ReCAPTCHA from "react-google-recaptcha";

import CustomButton from "../components/custom/CustomButton";
const industries = [
  "Select your industry",
  "Information Technology (IT) & Software",
  "Retail & E-commerce",
  "Education & E-learning",
  "Telecommunications & Media",
  "Finance & Banking",
];
const ContactUsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Select your industry");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState();
  const [message, setMessage] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [verified, setVerified] = useState(false);
  const recaptchaRef = useRef();

  const handleInputChange = (e) => {
    const originalValue = e.target.value;
    const cleanedValue = originalValue.replace(/\D+/g, ""); // Remove non-digits
    setPhoneNumber(cleanedValue); // Update state with cleaned value
    e.target.value = cleanedValue; // Update input field display
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("name");
    const phoneInput = document.getElementById("phone");
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === ""
    ) {
      alert("Please enter all required fields.");
      return;
    }

    if (!emailPattern.test(emailInput.value)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!verified) {
      alert("Please complete the reCAPTCHA verification.");
      return; // Prevent form submission
    }

    const response = await fetch(
      "https://37vv41pax9.execute-api.us-east-1.amazonaws.com/sixlogs_website_contactus",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userEmail: email,
          userPhoneNumber: phoneNumber,
          userCompanyName: companyName,
          industry: selected,
          userMessage: message,
        }),
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      alert(responseData.statusText || "Email sent successfully!");
    } else {
      alert("Something went wrong. Please try again.");
    }

    // const responseData = await response.json();
    // alert(response.statusText);
    // console.log(responseData.statusText, "check");
    document.getElementById("myForm").reset();
    // Reset dropdown to "Select your industry"
    setSelected("Select your industry");

    // Close dropdown if open
    setIsOpen(false);
  };

  function onChange(value) {
    setVerified(true);
  }

  return (
    <div className="Contact-Us">
      <div className="box">
        <div className="custom-container">
          <div className="text-center pt-5 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-column flex-md-row gap-2 align-items-center">
              <h1 className="characterbackground h1">
                <div
                  style={{
                    padding: "0px 4%",
                    margin: "0px -4%",
                  }}
                >
                  B
                </div>
                <span>uild your</span>
              </h1>
              <h1 className="h1">
                <span className="d-lg-block">dream Projects</span>
              </h1>
            </div>
          </div>
          <form onSubmit={handleSubmit} id="myForm">
            <div className="row mt-lg-5">
              <div className="col-lg-4 mt-5 ">
                <input
                  placeholder="Your Name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-lg-4 mt-5 ">
                <input
                  placeholder="Your e-mail*"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-lg-4 mt-5 ">
                <input
                  placeholder="Phone Number"
                  id="phone"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-lg-4 mt-5 ">
                <input
                  placeholder="Company Name"
                  id="companyName"
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="col-lg-4 mt-5 position-relative">
                <div
                  className="custom-dropdown border-bottom border-dark d-flex justify-content-between align-items-center pt-2 px-3"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span
                    className={selected === industries[0] ? "text-muted" : ""}
                  >
                    {selected}
                  </span>
                  <span className="dropdown-icon">
                    <RiArrowDropDownLine size={30} />
                  </span>
                </div>

                {isOpen && (
                  <ul className="custom-dropdown-menu shadow rounded">
                    {industries.slice(1).map(
                      (
                        option,
                        index // Sliced array to exclude placeholder
                      ) => (
                        <li
                          key={index}
                          className="dropdown-item"
                          onClick={() => {
                            setSelected(option);
                            setIsOpen(false);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {option}
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
              <div className="col-lg-4 mt-5 ">
                <input
                  placeholder="Tell us about your project"
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdIc-wqAAAAAHVUQvr7C-K4UYi79dQFf0uVbzm_"
              onChange={onChange}
              className="mb-4"
            />
            <div className="d-flex align-items-center justify-content-center py-3 py-lg-5">
              <CustomButton btntext={"Send"} customStyle={{}} />
            </div>
          </form>
        </div>
      </div>
      <div className="custom-container">
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-md-center gap-5">
          <div className="d-flex flex-column align-items-lg-center col-md-4 my-md-5">
            <div className="d-flex gap-3">
              <div>
                <img loading="lazy" src={MailIcon} />
              </div>

              <div className="">
                <h3 className="para">Email us</h3>
                <h4>
                  <a
                    href="mailto:info@sixlogs.com"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    info@sixlogs.com
                  </a>
                </h4>
              </div>
            </div>
          </div>
          {/* <div className="d-flex flex-column align-items-lg-center col-lg-4 my-3 my-lg-5">
            <div className="d-flex gap-3">
              <div>
                <img loading="lazy" src={CallIcon} />
              </div>

              <div className="">
                <h3 className="para">Call us</h3>
                <h4 role="button">
                  <a
                    href="tel:+17132132908"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    +1-713-213-2908
                  </a>
                </h4>
              </div>
            </div>
          </div> */}
          <div className="d-flex flex-column align-items-lg-center col-lg-4 mb-5 my-md-5">
            <div className="d-flex  gap-3">
              <div>
                <img loading="lazy" src={locationIcon} />
              </div>

              <div className="">
                <h3 className="para">Office address</h3>
                <h4>
                  <a
                    href="https://www.google.com/maps/place/13100+Wortham+Center+Dr+3rd+floor,+Suite+358,+Houston,+TX+77065,+USA/@29.9205603,-95.6180849,17z/data=!3m1!4b1!4m5!3m4!1s0x8640d11267ecbbaf:0x1a9b55bb1fb96abd!8m2!3d29.9205603!4d-95.61551?entry=ttu&g_ep=EgoyMDI1MDMxNi4wIKXMDSoASAFQAw%3D%3D"
                    style={{ textDecoration: "none", color: "black" }}
                    target="_blank"
                  >
                    13100 Wortham Center Dr 3rd floor,
                    <span className="d-xl-block">
                      {" "}
                      Suite 358 Houston, TX 77065, USA
                    </span>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3471.2700152952033!2d-95.61808492434942!3d29.920560281931448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d11267ecbbaf%3A0x1a9b55bb1fb96abd!2s13100%20Wortham%20Center%20Dr%203rd%20floor%2C%20Suite%20358%2C%20Houston%2C%20TX%2077065%2C%20USA!5e0!3m2!1sen!2s!4v1700000000000!5m2!1sen!2s"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
