import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-rigging-1.png";
import explore2 from "../assets/images/explore/explore-rigging-2.png";
import exploreMap from "../assets/images/explore/explore-rigging-3.png";

import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import AngularJsIcon from "../assets/images/explore/AngularJsIcon.svg";
import SalesforceIcon from "../assets/images/explore/SalesforceIcon.svg";
import "./MusikarExplorePage.css";

function AbleRiggingExplorePage() {
  const bannerData = {
    heading: "Optimizing Lifting Operations with ABLE Equipment",
    description:
      " Discover how ABLE Equipment is transforming the equipment industry with advanced planning, execution, and project management tools. By facilitating seamless collaboration with industry experts and access to essential resources, ABLE Equipment enhances efficiency and safety in complex lifting projects. ",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "ABLE Equipment is a specialized equipment platform designed to plan, execute, and manage complex lifting projects while improving coordination with industry experts and resources.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Equipment Lifting Project Planning",
        "Equipment Management",
        "Safety & Compliance",
        "Collaboration Tool",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "Web Development",
        "Equipment Management",
        "Project Planning",
        "Compliance Features",
        "QA & Testing",
        "Deployment & Support",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Rigging and lifting projects require precision, safety, and expert coordination. The client needed a robust platform that streamlines project planning, equipment tracking, and compliance, ensuring smooth and secure operations.",
    heading2: "About the Product",
    description2:
      "ABLE Equipment is a rigging and equipment management platform that optimizes project execution by integrating planning, collaboration, and safety compliance. It enables businesses to manage resources effectively while ensuring regulatory adherence and operational efficiency.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Rigging Project Planning – Strategize lifting operations with precision.",
      "Equipment Inventory Tracking – Manage machinery availability and usage.",
      "Logistics & Transportation – Optimize delivery of rigging tools.",
      "Compliance Documentation – Maintain safety certifications and records.",
      "Real-Time Project Monitoring – Track performance and milestones.",
      "Contractor Coordination – Assign and manage project teams seamlessly.",
      "Remote Equipment Monitoring – Access operational insights from anywhere.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      // {
      //   img_src: IOSIcon,
      //   description:
      //     "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      // },
      // {
      //   img_src: AndroidIcon,
      //   description:
      //     "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      // },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
      {
        img_src: SalesforceIcon,
        description:
          "We use Salesforce as a leading CRM platform that enhances customer relationship management and business process automation.",
      },
      {
        img_src: AngularJsIcon,
        description:
          "We use Angular as a powerful front-end framework that enables the development of dynamic and scalable web applications.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default AbleRiggingExplorePage;
