import React from "react";
import "./CoreValues.css";
import Corevalue1 from "../assets/images/Corevalue1.svg";
import Corevalue2 from "../assets/images/Corevalue2.svg";
import Corevalue3 from "../assets/images/Corevalue3.svg";
import Corevalue4 from "../assets/images/coreValue-4.svg";
import Corevalue5 from "../assets/images/coreValue-5.svg";
import Corevalue6 from "../assets/images/coreValue-6.svg";

function CoreValues() {
  const values = [
    {
      imgSrc: Corevalue1,
      title: "Exceed clients’ and colleagues’ expectations",
      description:
        "We go above and beyond to deliver outstanding results and create a supportive, collaborative workplace.",
    },
    {
      imgSrc: Corevalue2,
      title:
        "Take ownership and question the status quo in a constructive manner.",
      description:
        "We encourage our team to trust their instincts, provide constructive feedback, and challenge processes to drive improvement.",
    },
    {
      imgSrc: Corevalue3,
      title:
        "Be brave, curious and experiment – learn from all successes and failures",
      description:
        "Innovation thrives on risk-taking and learning from every outcome, driving continuous growth and improvement.",
    },
    {
      imgSrc: Corevalue4,
      title: "Act in a way that makes all of us proud",
      description:
        "Our diversity initiatives have been recognized by Comparably, a platform that provides culture and compensation data for public .",
    },
    {
      imgSrc: Corevalue5,
      title: "Build an inclusive, transparent and socially responsible culture",
      description:
        "Trust your gut and your knowledge, even if you need to question your team leader or CEO.",
    },
    {
      imgSrc: Corevalue6,
      title: "Be ambitious and grow yourself and the people around you",
      description:
        "Take risks and make mistakes - that’s how we learn, and how we innovate.",
    },
  ];
  return (
    <div className="corevalues">
      <div className="custom-container py-5">
        <div className="row mt-md-5">
          <div className="col-lg-6 pe-lg-5">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-offset="30%"
            >
              <div className="d-flex gap-2 gap-md-3 align-items-center ">
                <h1 className="h1">
                  <span className="d-lg-block">Sixlogs</span>
                </h1>
                <h1 className="characterbackground h1">
                  <div
                    style={{
                      padding: "0px 4%",
                      margin: "0px -4%",
                    }}
                  >
                    C
                  </div>
                  <span>ore</span>
                </h1>
              </div>

              <h1 className="h1">Values</h1>
            </div>
            <h3 className="mt-md-5 pe-lg-5">
              At Sixlogs, our core values shape our culture and drive our
              success. We are dedicated to exceeding expectations, fostering an
              environment of ownership and constructive challenge, and embracing
              bravery, curiosity, and experimentation.
            </h3>
          </div>
          <div className="col-lg-6 box">
            {values.map((item, index) => (
              <div
                className={`innerbox p-4 mt-3 mb-3 d-flex flex-column flex-sm-row gap-4 font-face-DMSans-Regular`}
                key={index}
              >
                <div className="col-sm-3">
                  <img loading="lazy"
                    src={item.imgSrc}
                    alt={item.title}
                    className="img-fluid"
                  />
                </div>
                <div className="col-sm-9 pe-4">
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValues;
