import React from "react";
import HeroBanner from "../components/HeroBanner";
import ServicesCard from "../components/ServicesCard";
import ClientsExperience from "../components/ClientsExperience";
import TeamAchievements from "../components/TeamAchievements";
import salesforce from "../assets/images/SalesForceCloud.png";
import AgentForce from "../assets/images/agentforce-banner.svg";
import ContactForm from "../components/ContactForm";
import "./InnerPages.css";
import FeedBack from "../components/FeedBack";
import Platform1 from "../assets/images/inner-pages-images/salesforce-service-1.svg";
import Platform2 from "../assets/images/inner-pages-images/salesforce-service-2.svg";
import SolutionOverviewImg from "../assets/images/inner-pages-images/salesforce-service-3.svg";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import SolutionOverview from "../components/SolutionOverview";
import ReadyToBuild from "../components/ReadyToBuild";

function SalesForceServicesPage() {
  const bannerData = {
    pageName: "AgentForce",
    description: [
      "At Sixlogs Technologies, we specialize in delivering customized AgentForce solutions to optimize your business processes and maximize efficiency. Our expert implementation services help you leverage the full potential of the AgentForce platform, enabling seamless automation, enhanced customer engagement, and data-driven decision-making to drive growth and achieve your strategic goals.",
      "With a deep understanding of industry best practices, our team ensures a smooth integration of AgentForce into your existing workflow. Whether you need CRM customization, workflow automation, or advanced analytics, we provide end-to-end support to help you unlock new opportunities and stay ahead in a competitive market",
    ],
    imgsrc: AgentForce,
  };

  const serviceData = [
    {
      title: "Sales Cloud",
      description:
        "Unlock the full potential of your Salesforce Sales Cloud implementation with Sixlogs Technologies. We ensure that your sales processes are optimized for maximum efficiency and productivity.",
    },
    {
      title: "CPQ",
      description:
        "Simplify the complexity of your sales process with our CPQ solutions. Sixlogs Technologies ensures you get the most out of your Salesforce CPQ implementation by automating product configuration, pricing, and quoting.",
    },
    {
      title: "Service Cloud",
      description:
        "Deliver exceptional customer service with Salesforce Service Cloud. At Sixlogs Technologies, we help you leverage Service Cloud to improve case management, service automation, and customer support operations.",
    },
    {
      title: "Marketing Cloud",
      description:
        "Transform your marketing strategies with Salesforce Marketing Cloud. Our implementation services at Sixlogs Technologies help you create personalized customer journeys across multiple channels.",
    },
    {
      title: "Community Cloud",
      description:
        "Build vibrant online communities with Salesforce Community Cloud. Sixlogs Technologies specializes in implementing Community Cloud solutions that connect customers, partners, and employees.",
    },
    {
      title: "Commerce Cloud",
      description:
        "Enhance your e-commerce operations with Salesforce Commerce Cloud. At Sixlogs Technologies, we help you implement Commerce Cloud to deliver personalized shopping experiences and manage your online store efficiently.",
    },
  ];

  const platformData = {
    pageName: "salesforce",
    imgsrc: Platform1,
    title: "Empower Your Business with Custom Platform Development",
    description:
      "At Sixlogs Technologies, we help you build, run, customize, and manage advanced platforms tailored to your business needs. Our expertise in platform development ensures that your solutions are not only robust and secure but also scalable and user-friendly. We leverage cutting-edge tools and frameworks to create platforms that drive efficiency and innovation.",
    subheading: "Our platform development services include:",

    unOrderData: [
      {
        ulHeading: "Custom Development:",
        ulDescription:
          "We design and develop platforms that align with your specific business requirements, ensuring a perfect fit for your operations.",
      },
      {
        ulHeading: "Integration:",
        ulDescription:
          "Seamlessly integrate your platform with existing systems and third-party applications to ensure smooth data flow and operational harmony.",
      },
      {
        ulHeading: "Scalability:",
        ulDescription:
          "Build scalable platforms that grow with your business, supporting increasing data volumes and user loads without compromising performance.",
      },
      {
        ulHeading: "Security:",
        ulDescription:
          "Implement strong security measures to protect your platform from vulnerabilities and ensure data privacy and integrity.",
      },
    ],
  };

  const lightningData = {
    pageName: "salesforce",
    imgsrc: Platform2,
    title: "Accelerate Your Business with Salesforce Lightning Development",
    description:
      "At Sixlogs Technologies, we specialize in Salesforce Lightning development, helping you harness the power of this advanced platform to transform your business operations. Salesforce Lightning is designed to provide a sleek, user-friendly interface and powerful features that enhance productivity and improve user experience",
    subheading: "Our Lightning development services include:",

    unOrderData: [
      {
        ulHeading: "Custom Lightning Apps:",
        ulDescription:
          "Develop bespoke Lightning applications tailored to your specific business needs, ensuring a seamless user experience and optimized workflows.",
      },
      {
        ulHeading: "Lightning Migration:",
        ulDescription:
          "Transition smoothly from Salesforce Classic to Lightning Experience with our expert migration services. We ensure that your data and customizations are migrated securely and efficiently.",
      },
      {
        ulHeading: "Component Development:",
        ulDescription:
          "Create custom Lightning components that extend the functionality of your Salesforce environment, providing users with intuitive and powerful tools.",
      },
      {
        ulHeading: "Integration Solutions:",
        ulDescription:
          "Integrate Salesforce Lightning with other systems and third-party applications to streamline operations and enhance data flow.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "salesforce",
    title: "Modernize your business with Cloud Solutions",
    imgsrc: SolutionOverviewImg,
    description: [
      "Cloud computing is the driving force behind the digital transformation, and the world is witnessing a significant shift towards cloud-based solutions. At Sixlogs Technologies, we stay ahead of the curve by keeping up with the latest industry trends and best practices to ensure our clients have access to the most advanced digital and business solutions available.",
      "Our proficient Salesforce integration team helps revolutionize and digitize your business processes and workflows with our cloud innovations. Our comprehensive cloud computing services enable our clients to access business software and applications from anywhere across the globe. Moving to the cloud not only enhances productivity and agility but also optimizes operational efficiency and reduces costs.",
      "At Sixlogs, we are dedicated to guiding you through the complexities of digital transformation and cloud adoption. Our team ensures that you benefit from higher productivity and efficiency, maximizing your investment in cloud technologies.",
    ],
  };

  return (
    <div>
      <HeroBanner data={bannerData} />
      <ServicesCard serviceData={serviceData} />
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      <TeamAchievements />
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default SalesForceServicesPage;
