import React from "react";
import ExploreBanner from "../components/ExploreBanner";
import ExploreAbout from "../components/ExploreAbout";
import ExploreFeature from "../components/ExploreFeature";
import ContactForm from "../components/ContactForm";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";
import explore1 from "../assets/images/explore/explore-home-1.png";
import explore2 from "../assets/images/explore/explore-home-2.png";
import exploreMap from "../assets/images/explore/explore-home-3.png";
import FigmaIcon from "../assets/images/explore/Figma.svg";
import ConfluenceIcon from "../assets/images/explore/ConfluenceIcon.svg";
import IOSIcon from "../assets/images/explore/IOSIcon.svg";
import AndroidIcon from "../assets/images/explore/AndroidIcon.svg";
import JiraIcon from "../assets/images/explore/JiraIcon.svg";
import NodejsIcon from "../assets/images/explore/NodejsIcon.svg";
import BitBucketIcon from "../assets/images/explore/BitBucketIcon.svg";
import AWSIcon from "../assets/images/explore/AWSIcon.svg";
import PostmanIcon from "../assets/images/explore/PostmanIcon.svg";
import StripeIcon from "../assets/images/explore/StripeIcon.svg";
import SeleniumIcon from "../assets/images/explore/SeleniumIcon.svg";
import ExpressJsIcon from "../assets/images/explore/ExpressJsIcon.svg";
import "./MusikarExplorePage.css";

function HomeQExplorePage() {
  const bannerData = {
    heading: "Enhancing Home Services with Home Q",
    description:
      "Discover how Home Q is revolutionizing labor services and home maintenance for professionals. By enabling seamless service offerings, job management, and connections with homeowners and contractors, Home Q simplifies operations and expands opportunities.",
    img_src: explore1,
    aboutProject: {
      heading: "About This Project",
      description:
        "Home Q is a labor service and home maintenance platform designed to connect professionals with homeowners and contractors, simplify service management, and expand job opportunities.",
    },
    services: {
      heading: "Services",
      servicePoint: [
        "Service Marketplace",
        "Job Management",
        "Scheduling & Payments",
        "Contractor Networking",
      ],
    },
    scope: {
      heading: "Scope",
      scopePoint: [
        "Requirement Analysis",
        "UI Prototyping",
        "App Development",
        "Job Matching",
        "Service Scheduling",
        "Payment Integration",
        "Testing & QA",
        "App Release",
      ],
    },
  };

  const aboutData = {
    heading1: "About the Client",
    description1:
      "Home Q was developed to connect skilled professionals with homeowners and businesses in need of maintenance and repair services. The client aimed to provide a seamless job marketplace that ensures reliability and easy service management.",
    heading2: "About the Product",
    description2:
      "Home Q is a home maintenance and labor service platform that streamlines service bookings, payment processing, and contractor management. It offers a trusted marketplace for home improvement, making it easy for professionals and clients to connect.",
    img_src: explore2,
  };

  const featureData = {
    roleHeading: "Features",
    rolePoints: [
      "Service Marketplace – Connect homeowners with verified professionals.",
      "Job Scheduling – Book home services with flexible appointment options.",
      "Instant Pricing Estimates – Get accurate service cost predictions.",
      "Automated Payment Processing – Secure transactions for service bookings.",
      "Contractor Ratings & Reviews – Ensure quality service providers.",
      "Task Management – Track ongoing and completed service requests.",
      "Referral Program – Earn rewards for recommending professionals.",
    ],
    approachHeading: "Technologies We use",
    technologies: [
      // {
      //   img_src: ConfluenceIcon,
      //   description:
      //     "We use Confluence as a collaborative documentation and project management tool that streamlines workflows and enhances team coordination.",
      // },
      {
        img_src: FigmaIcon,
        description:
          "We use Figma as a powerful design and prototyping tool enabling real-time collaboration for UI/UX development and wireframing.",
      },
      {
        img_src: IOSIcon,
        description:
          "We use iOS as a secure and high-performance mobile operating system for Apple devices, supporting seamless app development.",
      },
      {
        img_src: AndroidIcon,
        description:
          "We use Android as an open-source platform providing scalability and flexibility for mobile application development.",
      },
      {
        img_src: JiraIcon,
        description:
          "We use Jira as an agile project management tool that helps teams track tasks, manage sprints, and optimize workflows efficiently.",
      },
      {
        img_src: NodejsIcon,
        description:
          "We use Node.js as a high-speed, scalable JavaScript runtime for building robust and efficient backend applications.",
      },
      {
        img_src: PostmanIcon,
        description:
          "We use Postman as a widely used API testing and collaboration tool for debugging, monitoring, and automating API workflows.",
      },
      {
        img_src: BitBucketIcon,
        description:
          "We use Bitbucket as a Git-based repository management system supporting CI/CD pipelines and team collaboration on code.",
      },
      {
        img_src: AWSIcon,
        description:
          "We use AWS as a leading cloud computing platform offering secure, scalable, and cost-effective infrastructure solutions.",
      },
      // {
      //   img_src: StripeIcon,
      //   description:
      //     "We use Stripe as a reliable payment processing solution that facilitates seamless online transactions and financial automation.",
      // },
      // {
      //   img_src: SeleniumIcon,
      //   description:
      //     "We use Selenium as a robust automated testing framework to ensure the quality and reliability of web applications across browsers.",
      // },
      {
        img_src: ExpressJsIcon,
        description:
          "We use Express.js as a minimal and flexible Node.js framework that simplifies backend development and API creation.",
      },
    ],
    img_src: exploreMap,
  };

  return (
    <>
      <div className="explorePage">
        <ExploreBanner data={bannerData} />
        <ExploreAbout data={aboutData} />
        <ExploreFeature data={featureData} />
      </div>
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </>
  );
}

export default HomeQExplorePage;
