import React, { useState } from "react";
import Itsolution from "../assets/images/itsolution.svg";

import HeroBanner from "../components/HeroBanner";
import DigitalServices from "../components/DigitalServices";
import ServicesCard from "../components/ServicesCard";
import ClientsExperience from "../components/ClientsExperience";
import ContactForm from "../components/ContactForm";
import EnhancedBusiness from "../components/EnhancedBusiness";
import SolutionOverview from "../components/SolutionOverview";
import Platform1 from "../assets/images/inner-pages-images/it-solution-1.svg";
import Platform2 from "../assets/images/inner-pages-images/it-solution-2.svg";
import SolutionOverviewImg from "../assets/images/inner-pages-images/it-solution-3.svg";
import PlatformDevelopment from "../components/PlatformDevelopment";
import LightningDevelopment from "../components/LightningDevelopment";
import FeedBack from "../components/FeedBack";
import ReadyToBuild from "../components/ReadyToBuild";

function ITSolutionPage() {
  const bannerData = {
    pageName: "IT Solutions",
    description: [
      "In the current fast-paced digital world, IT solutions that evolve with your business is essential. At Sixlogs Technologies, we offer personalized IT support to cater to your unique business needs and propel your growth. Our comprehensive IT services cover strategic planning, technical setup, and continuous maintenance, ensuring your systems are always running optimally.",
      "Our approach to IT support is not just about implementing the latest technology; it’s about understanding your business goals and aligning our solutions to meet them. We delve deep into market trends, user experience design, and cutting-edge development practices to create IT systems that are both robust and scalable. Our team ensures that every component of your IT infrastructure is fine-tuned for maximum efficiency, security, and flexibility.",
    ],
    imgsrc: Itsolution,
  };

  const serviceData = [
    {
      title: "Proactive Monitoring",
      description:
        "Sixlogs Technologies ensures IT stability with proactive monitoring. We detect and resolve potential issues early, minimizing downtime and optimizing performance.",
    },
    {
      title: "Customized IT Roadmaps",
      description:
        "Tailored IT roadmaps from Sixlogs Technologies align with your business goals. We enhance infrastructure, improve efficiency, and drive innovation with strategic planning.",
    },
    {
      title: "Expert IT Team",
      description:
        "Sixlogs Technologies’ IT experts deliver top-tier support. From troubleshooting to advanced technology implementation, we ensure seamless and efficient IT operations.",
    },
    {
      title: "Targeted Paid Advertising",
      description:
        "Maximize ROI with Sixlogs Technologies’ targeted paid ads. Our PPC campaigns leverage precise targeting and continuous optimization across Google and social media.",
    },
    {
      title: "Lead Generation and Management",
      description:
        "Convert potential customers with Sixlogs Technologies’ lead strategies. We attract, capture, and nurture leads using data-driven engagement and personalized content.",
    },
    {
      title: "Website Performance and Traffic Analysis",
      description:
        "Optimize website performance with Sixlogs Technologies. We analyze user behavior, speed, and engagement to enhance experience and improve search rankings.",
    },
  ];

  const platformData = {
    pageName: "it-solution",
    imgsrc: Platform1,
    title: "Optimize Operations – Drive Growth",
    description:
      "At Sixlogs Technologies, we enhance your IT infrastructure with customized solutions that improve efficiency and ensure seamless operations.",
    subheading: "Our services include:",

    unOrderData: [
      {
        ulHeading: "Tailored IT Strategies:",
        ulDescription:
          "Customized solutions designed to align with your business goals.",
      },
      {
        ulHeading: "24/7 IT Support:",
        ulDescription:
          "Continuous help desk assistance to keep your systems running smoothly.",
      },
      {
        ulHeading: "Advanced Cybersecurity:",
        ulDescription:
          "Robust security protocols to safeguard your digital assets.",
      },
      {
        ulHeading: "Cloud Solutions:",
        ulDescription:
          "Scalable cloud services for secure data management and accessibility.",
      },
      {
        ulHeading: "Network Management:",
        ulDescription:
          "Reliable infrastructure solutions for optimized performance.",
      },
      {
        ulHeading: "Expert IT Consultancy:",
        ulDescription: "Strategic guidance to navigate complex IT challenges.",
      },
    ],
  };

  const lightningData = {
    pageName: "it-solution",
    imgsrc: Platform2,
    title: "Empowering Businesses with Reliable IT Solutions",
    description:
      "At Sixlogs Technologies, we focus on streamlining IT operations to help businesses achieve long-term stability and efficiency. Our solutions are designed to optimize workflows, enhance security, and ensure seamless digital transformation.",
    subheading: "Our core offerings include:",

    unOrderData: [
      {
        ulHeading: "Business Process Automation:",
        ulDescription:
          "Eliminate manual inefficiencies with intelligent automation.",
      },
      {
        ulHeading: "IT Risk Management:",
        ulDescription:
          "Proactively identify and mitigate technological vulnerabilities.",
      },
      {
        ulHeading: "Scalable Cloud Infrastructure:",
        ulDescription:
          "Support business expansion with flexible cloud solutions.",
      },
      {
        ulHeading: "Enterprise IT Support:",
        ulDescription:
          "Minimize downtime with expert troubleshooting and assistance.",
      },
      {
        ulHeading: "Data Backup & Recovery:",
        ulDescription:
          "Secure critical information with reliable recovery solutions.",
      },
    ],
  };

  const solutionOverviewData = {
    pageName: "it-solution",
    title: "Modernize your business with Cloud Solutions",
    imgsrc: SolutionOverviewImg,
    description: [
      "At Sixlogs Technologies, we deliver forward-thinking IT strategies that enable businesses to navigate the complexities of today’s digital world. Our expertise ensures that organizations can streamline operations, optimize resources, and reduce costs while maintaining peak performance. By implementing cutting-edge solutions, we help businesses stay competitive and resilient in a rapidly evolving market.",
      "Technology-driven efficiency is at the core of our approach. We craft tailored IT solutions that align with business objectives, enhancing system performance, scalability, and security. Whether optimizing existing infrastructure or integrating new technologies, we ensure seamless adaptability to meet changing industry demands.",
      "Future-proofing your business requires a strategic IT roadmap. Our team works closely with you to develop long-term plans that enhance cyber resilience, maximize cloud capabilities, and drive sustainable growth. With Sixlogs Technologies as your IT partner, your business gains the agility and strength to thrive in an ever-changing digital environment.",
    ],
  };
  return (
    <div>
      <HeroBanner data={bannerData} />
      {/* <EnhancedBusiness /> */}
      <ServicesCard serviceData={serviceData} />{" "}
      <PlatformDevelopment data={platformData} />
      <LightningDevelopment data={lightningData} />
      <SolutionOverview data={solutionOverviewData} />
      <ReadyToBuild />
      <FeedBack isInnerService={true} />
      <ContactForm />
    </div>
  );
}

export default ITSolutionPage;
