import React from "react";
import "./OurExpertise.css";
import ArrowRight from "../assets/images/arrowright.svg";
import CustomButton from "./custom/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomLineText from "./custom/CustomLineText";

const OurExpertise = () => {
  const navigate = useNavigate();
  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const OurExpertise = [
    {
      title: "Architect Your Solution",
      discription:
        "Our Solution Architects zero in on your operational, technological, and strategic challenges through an in-depth understanding of your business.",
    },
    {
      title: "Engineer Your Vision",
      discription:
        "We quickly assemble your nearshore engineering dream team to meet your unique needs and goals. Using our deep tech.",
    },
    {
      title: "Push Boundaries",
      discription:
        "Supported by our team, your digital transformation swiftly gains momentum and evolves into a digital acceleration.",
    },
  ];
  return (
    <div className="OurExpertise">
      <div className="custom-container">
        <div className="pb-2">
          <CustomLineText text="Expertise" />
          <div className="d-flex flex-wrap justify-content-between gap-3 align-items-center">
            <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
              <h1>We are Software</h1>
              <div className="d-md-flex gap-md-3 align-items-center">
                <h1 className="pr-5">Development</h1>
                <h1 className="characterbackground">
                  <div className="circle-spacing">E</div>
                  <span>xperts</span>
                </h1>
              </div>
            </div>
            <CustomButton
              btntext={"Contact for Custom Project"}
              func={() => navigateToPage("/contact-us")}
              customStyle={{
                padding: "15px",
                fontSize: "clamp(16px, 1vw, 20px)",
                borderRadius: 10,
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-3 gap-xl-4 mt-5 font-face-DMSans-Regular">
          {OurExpertise.map((item, index) => (
            <div
              onClick={() => navigateToPage("/contact-us")}
              key={index}
              className="col-lg-4 card"
            >
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="">{item.title}</h3>
                  <div>
                    <img loading="lazy" src={ArrowRight} alt="" />
                  </div>
                </div>
                <h4 className="mt-3">{item.discription}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurExpertise;
