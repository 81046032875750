import React, { useEffect, useState } from "react";
import "./NavigationBar.css";
import logo from "../assets/images/logo.svg";
import NavbarLogoWhite from "../assets/images/NavbarLogoWhite.svg";
import hamIcon from "../assets/images/hamIcon.svg";
import blackHamIcon from "../assets/images/blackHamIcon.svg";
import whiteCloseIcon from "../assets/images/whiteCloseIcon.svg";
import { MdOutlineArrowOutward } from "react-icons/md";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const NavigationBar = ({ isHover }) => {
  const [isShow, setShow] = useState(false);
  const [BgColor, setBgColor] = useState("transparent");
  const location = useLocation();

  const navigate = useNavigate();

  const navigateToPage = (path, newTab = false) => {
    if (newTab) {
      window.open(path, "_blank");
    } else if (path === "#services") {
      navigate("/");
      setTimeout(() => {
        window.location.hash = "services";
      }, 100);
    } else {
      navigate(path);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
    }

    setShow(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 40) {
        setBgColor("white");
      } else {
        setBgColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navItems = [
    { title: "Home", newTab: false, link: "/" },
    // { title: "Services", newTab: false, link: "/salesforce-services" },
    {
      title: "Portfolio",
      newTab: true,
      link: "https://portfolio.sixlogs.com/",
    },
    { title: "Services", newTab: false, link: "#services" },
    { title: "Clients", newTab: false, link: "/clients" },
    { title: "Careers", newTab: false, link: "/careers" },
    { title: "About", newTab: false, link: "/about-us" },
    { title: "Contact", newTab: false, link: "/contact-us" },
  ];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <div
      className="navigation-bar"
      style={{
        borderBottom:
          location.pathname == "/" && BgColor !== "white"
            ? "1px solid gray"
            : location.pathname == "/ai"
            ? "1px solid gray"
            : "",
      }}
    >
      <header
        style={{
          backgroundColor:
            location.pathname == "/"
              ? BgColor
              : location.pathname == "/ai"
              ? "#0e0e0e"
              : "white",
        }}
        className="navigation-header"
      >
        {location.pathname !== "/ai" ? (
          <div className="logo-container">
            {location.pathname == "/" && !isTabletOrMobile && (
              <div onClick={() => navigateToPage("/")}>
                <img
                  loading="lazy"
                  src={NavbarLogoWhite}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" && isHover ? "" : "hidden"
                  }`}
                />
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" && isHover ? "hidden" : ""
                  }`}
                />
              </div>
            )}
            {location.pathname == "/" && isTabletOrMobile && (
              <div onClick={() => navigateToPage("/")}>
                <img
                  loading="lazy"
                  src={NavbarLogoWhite}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" ? "" : "hidden"
                  }`}
                />
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" ? "hidden" : ""
                  }`}
                />
              </div>
            )}
            {location.pathname !== "/" && (
              <div onClick={() => navigateToPage("/")}>
                <img
                  loading="lazy"
                  src={NavbarLogoWhite}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" && isHover ? "" : "hidden"
                  }`}
                />
                <img
                  loading="lazy"
                  src={logo}
                  alt="logo"
                  className={`logo-image ${
                    BgColor !== "white" && isHover ? "hidden" : ""
                  }`}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="logo-container">
            <div onClick={() => navigateToPage("/")}>
              <img loading="lazy" src={NavbarLogoWhite} alt="logo" />
            </div>
          </div>
        )}

        {/* let's talk start */}
        <div className="nav-actions">
          {location.pathname !== "/ai" ? (
            <>
              {location.pathname == "/" && !isTabletOrMobile && (
                <h5
                  onClick={() => {
                    navigateToPage("/contact-us");
                  }}
                  style={{
                    color: BgColor != "white" && isHover ? "white" : "black",
                    transition: "all 2s ease",
                    cursor: "pointer",
                  }}
                  role="button"
                >
                  Let's Talk
                  <MdOutlineArrowOutward className="icon" />
                </h5>
              )}
              {location.pathname == "/" && isTabletOrMobile && (
                <h5
                  onClick={() => {
                    navigateToPage("/contact-us");
                  }}
                  style={{
                    color: BgColor != "white" ? "white" : "black",
                    transition: "all 2s ease",
                    cursor: "pointer",
                  }}
                  role="button"
                >
                  Let's Talk
                  <MdOutlineArrowOutward className="icon" />
                </h5>
              )}
              {location.pathname !== "/" && (
                <h5
                  onClick={() => {
                    navigateToPage("/contact-us");
                  }}
                  style={{
                    color: "black",
                    transition: "all 2s ease",
                    cursor: "pointer",
                  }}
                  role="button"
                >
                  Let's Talk
                  <MdOutlineArrowOutward className="icon" />
                </h5>
              )}
            </>
          ) : (
            <h5
              onClick={() => {
                navigateToPage("/contact-us");
              }}
              style={{
                color: "white",
                transition: "all 2s ease",
                cursor: "pointer",
              }}
              role="button"
            >
              Let's Talk
              <MdOutlineArrowOutward className="icon" />
            </h5>
          )}
          {/* let's talk end */}

          {location.pathname !== "/ai" ? (
            <button
              style={{
                backgroundColor: location.pathname == "/ai" ? "white" : "",
              }}
              onClick={() => setShow(true)}
              className="nav-button"
            >
              <img loading="lazy" src={hamIcon} alt="Menu Icon" />
            </button>
          ) : (
            <button
              style={{
                backgroundColor: location.pathname == "/ai" ? "white" : "",
              }}
              onClick={() => setShow(true)}
              className="nav-button"
            >
              <img loading="lazy" src={blackHamIcon} alt="Menu Icon" />
            </button>
          )}
        </div>
      </header>

      <div className={`full-screen-menu ${isShow ? "fade-in" : "fade-out"}`}>
        <div className="menu-header">
          <button onClick={() => setShow(false)}>
            <img loading="lazy" src={whiteCloseIcon} alt="Menu Icon" />
          </button>
        </div>
        <div className="menu-content">
          <div>
            {navItems.map((item, index) => (
              <div
                onClick={() => navigateToPage(item?.link, item.newTab)}
                key={index}
                className="nav-link"
              >
                <p>{item.title}</p>
                <MdOutlineArrowOutward className="icon" color="gray" />
              </div>
            ))}

            {/*
            <div className="social-icons">
              <a
                href="https://www.facebook.com/sixlogstechnologies"
                target="_blank"
              >
                <FaFacebookF size={28} color={"white"} />
              </a>
              <a href="https://twitter.com/sixlogs">
                <FaTwitter size={28} color={"white"} />
              </a>  <a
                href="https://www.linkedin.com/company/sixlogs"
                target="_blank"
              >
                <FaLinkedinIn size={28} color={"white"} />
              </a> 
            </div>
              */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
