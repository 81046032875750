import React from "react";
import "./CareerBanner.css";
import careersimg1 from "../assets/images/careersimg1.png";
import careersimg2 from "../assets/images/careersimg2.png";
import careersimg3 from "../assets/images/careersimg3.png";
import careersimg4 from "../assets/images/careersimg4.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CareerBanner({ data }) {
  const sliderRef = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imgArray = [careersimg1, careersimg2, careersimg3, careersimg4];
  return (
    <div className="career-banner">
      <div className="career-inner custom-container pb-lg-5">
        <div className="d-flex ">
          {data.tittle.map((item, index) => (
            <h1 className="pt-lg-5 mt-lg-4 col-lg-5 pe-md-5" key={index}>
              {item}
            </h1>
          ))}
          <div className="p-3 col-lg-4 d-none d-lg-flex">
            <img loading="lazy" className="w-100" src={careersimg1} />
          </div>
          <div className="p-3 col-lg-3 d-none d-lg-flex align-items-end  ">
            <img loading="lazy" className="w-100" src={careersimg2} />
          </div>
        </div>
        <div className="d-flex align-items-start pt-2">
          {data.description.map((desc, index) => (
            <p key={index} className="pt-4 col-lg-6">
              {desc}
            </p>
          ))}
          <div className="col-lg-3 p-3 d-none d-lg-flex">
            <img loading="lazy" className="w-100" src={careersimg3} />
          </div>
          <div className="col-lg-3 p-3 d-none d-lg-flex">
            <img loading="lazy" className="w-100" src={careersimg4} />
          </div>
        </div>

        <div className="row d-lg-none ">
          <Slider ref={sliderRef} {...settings}>
            {imgArray.map((benefit, index) => (
              <div className="p-3" key={index}>
                <img
                  style={{ aspectRatio: "16/11", objectFit: "cover" }}
                  loading="lazy"
                  src={benefit}
                  alt={""}
                  className="img-fluid"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default CareerBanner;
