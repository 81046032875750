import React from "react";
import "./AboutPeople.css";
import AboutPeopleimg from "../assets/images/Career-section.svg";
import orangeCoomas from "../assets/images/orangeCoomas.svg";
import circle from "../assets/images/career-circle.png";

function AboutPeople() {
  return (
    <div className="aboutpeople p-0 m-0">
      <div className="custom-container">
        <div className="row d-lg-flex align-items-center">
          <div className="col-lg-6">
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="top-center"
              data-aos-offset="30%"
            >
              <h1 className="h1">
                <span className="d-lg-block">It's all about people</span>
              </h1>
              <div className="d-md-flex gap-2 align-items-center">
                <h1 className="characterbackground h1">
                  <div
                    style={{
                      padding: "0px 3%",
                      margin: "0px -3%",
                    }}
                  >
                    L
                  </div>
                  <span>et’s hear</span>
                </h1>
                <h1 className="h1">
                  <span className="d-lg-block">them out.</span>
                </h1>
              </div>
            </div>

            <div className="d-flex mt-5 gap-3  second-section">
              <div>
                <img loading="lazy" src={orangeCoomas} width={45} />
              </div>
              <div className="">
                <h3>
                  We’re building work environments in which we feel good –
                  supported and safe. Because of our awareness of other people’s
                  needs, of information exchange through the internet, we can
                  practice more empathy.
                </h3>
                <div className="d-flex gap-2 mt-5">
                  <div className="">
                    <h6 className="text-align-center">Taylor Roy Adam</h6>
                    <p className="">Production Head</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="img-section col-lg-6 d-flex justify-content-center align-items-center bg-white py-5">
            <img loading="lazy" src={circle} className="circle-img" />
            <img loading="lazy" src={AboutPeopleimg} className="img-fluid img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPeople;
