import React from "react";
import "./CustomLineText.css";
function CustomLineText({ color = "", text = "" }) {
  return (
    <div className="customlinetext font-face-Syne-Bold">
      <div style={{ backgroundColor: color }} className="line"></div>
      <h5 style={{ color: color }}>{text}</h5>
    </div>
  );
}

export default CustomLineText;
