import React from "react";
import CustomLineText from "./custom/CustomLineText";
import doublecoomas from "../assets/images/doublecoomas.svg";
import Founder from "../assets/images/founder.svg";

function FounderIntro() {
  const team = [
    { name: "Sarah", role: "Operations" },
    { name: "Nida", role: "Talent and Human Resource" },
    { name: "Zaki", role: "Engineering" },
    { name: "Unzar", role: "Revenue and Growth" },
  ];
  return (
    <div className="row d-flex flex-column flex-md-row align-items-center pt-xxl-5">
      <div className="col-md-3">
        <img loading="lazy" src={Founder} className="w-100" />
      </div>
      <div className="d-flex flex-column flex-sm-row gap-3 gap-md-5 col-md-9 col-xl-7 second-section mt-5 mt-md-0">
        <div className=" mt-2">
          <img loading="lazy" src={doublecoomas} style={{ width: 45 }} />
        </div>
        <div className="">
          <h3 className="font-face-Syne-Bold">
            From the outset, our primary objective has been to assist
            entrepreneurs in creating meaningful software solutions. We strive
            to build and deliver products that benefit people, contributing to a
            better world for all, including ourselves.
          </h3>
          <div className="pt-3">
            <CustomLineText text="Fawad Hassan" color="black" />
            <p
              style={{
                paddingLeft: "45px",
                fontSize: "18px",
                fontWeight: "500",
              }}
              className=""
            >
              Founder
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-3 mt-sm-5">
        {team.map((team, index) => (
          <div key={index} className="text-center p-2 col-sm-6 col-xl-3">
            <div className="text-center py-4 border">
              <h2 style={{ fontWeight: "600" }} className="p-0 m-0 font-face-Syne-Bold">
                {team.name}
              </h2>
              <p
                style={{
                  paddingLeft: "45px",
                  fontSize: "clamp(17px, 1vw, 20px)",
                  fontWeight: "500",
                  color: "gray",
                }}
                className="p-0 m-0"
              >
                {team.role}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FounderIntro;
