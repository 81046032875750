import React from "react";
import "./SeeMore.css";
import arrowgraydown from "../assets/images/arrowgraydown.svg";
import { MdOutlineArrowOutward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
function SeeMore() {
  const navigate = useNavigate();
  const navigateToPage = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };
  const data = [
    {
      subHeading: "Learn More ",
      heading: "About Us",
      description: "Connect with us today to ",
      description2: "discuss your next project",
      link: "/about-us",
    },
    {
      subHeading: "See Our ",
      heading: "Portfolio",
      description: "See our best projects ",
      description2: "and the stories behind them",
      link: "https://portfolio.sixlogs.com/",
    },
    {
      subHeading: "Learn More ",
      heading: "Career",
      description: "Explore Opportunities",
      description2: "",
      link: "/careers",
    },
  ];
  return (
    <div className="SeeMore">
      <div className="main-div">
        <div className="row d-flex m-0 mt-xxl-5 p-0">
          {data?.map((item, index) => (
            <div
              onClick={() =>
                item.link.startsWith("http")
                  ? window.open(item.link, "_blank", "noopener,noreferrer")
                  : navigateToPage(item.link)
              }
              key={index}
              className="learn-more-card col-xl-4 d-flex justify-content-center align-items-center"
            >
              <div className="learn-more-inner">
                <h3 className="p-0 m-0">{item?.subHeading}</h3>
                <div className="d-flex gap-md-5 align-items-start justify-content-between justify-content-xl-center p-0 m-0">
                  <div>
                    <h1 className="p-0 m-0">{item?.heading}</h1>
                    <h4 className="p-0 m-0">
                      {item?.description} <br />
                      {item?.description2}
                    </h4>
                  </div>
                  <div className="icon">
                    <MdOutlineArrowOutward className="m-0 p-0 fluid" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SeeMore;
